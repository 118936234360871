import React, { useState, useEffect } from 'react';
import { X, Search, UserMinus } from 'lucide-react';
import { collection, query, where, getDocs, updateDoc, doc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { toast } from 'react-hot-toast';

interface GroupMembersModalProps {
  isOpen: boolean;
  onClose: () => void;
  group: {
    id: string;
    group_name: string;
    total_seats: number;
    members?: string[];
  };
  onMembersUpdated: () => void;
}

interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export default function GroupMembersModal({ isOpen, onClose, group, onMembersUpdated }: GroupMembersModalProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [members, setMembers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen && group.members) {
      fetchMembers();
    }
  }, [isOpen, group.members]);

  const fetchMembers = async () => {
    if (!group.members?.length) {
      setMembers([]);
      return;
    }

    try {
      const usersRef = collection(db, 'users');
      const memberDocs = await Promise.all(
        group.members.map(userId => 
          getDocs(query(usersRef, where('__name__', '==', userId)))
        )
      );

      const memberUsers = memberDocs
        .flatMap(snapshot => snapshot.docs)
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        } as User));

      setMembers(memberUsers);
    } catch (error) {
      console.error('Error fetching members:', error);
      toast.error('Failed to load group members');
    }
  };

  const searchUsers = async (term: string) => {
    if (!term.trim() || term.length < 2) {
      setSearchResults([]);
      return;
    }

    try {
      setLoading(true);
      const usersRef = collection(db, 'users');
      const q = query(usersRef);
      const snapshot = await getDocs(q);

      const results = new Map<string, User>();

      snapshot.docs.forEach(doc => {
        const userData = doc.data();
        const searchableText = `${userData.email} ${userData.firstName} ${userData.lastName}`.toLowerCase();
        
        if (searchableText.includes(term.toLowerCase()) && !group.members?.includes(doc.id)) {
          if (!results.has(doc.id)) {
            results.set(doc.id, {
              id: doc.id,
              ...userData
            } as User);
          }
        }
      });

      setSearchResults(Array.from(results.values()));
    } catch (error) {
      console.error('Error searching users:', error);
      toast.error('Failed to search users');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    searchUsers(term);
  };

  const addMember = async (user: User) => {
    const seatsAvailable = group.total_seats - (group.members?.length || 0);
    if (seatsAvailable <= 0) {
      toast.error('No seats remaining in this group');
      return;
    }

    try {
      setLoading(true);
      const groupRef = doc(db, 'groups', group.id);
      
      await updateDoc(groupRef, {
        members: arrayUnion(user.id)
      });

      setMembers(prev => [...prev, user]);
      setSearchResults([]);
      setSearchTerm('');
      onMembersUpdated();
      toast.success('Member added successfully');
    } catch (error) {
      console.error('Error adding member:', error);
      toast.error('Failed to add member');
    } finally {
      setLoading(false);
    }
  };

  const removeMember = async (user: User) => {
    try {
      setLoading(true);
      const groupRef = doc(db, 'groups', group.id);
      
      await updateDoc(groupRef, {
        members: arrayRemove(user.id)
      });

      setMembers(prev => prev.filter(m => m.id !== user.id));
      onMembersUpdated();
      toast.success('Member removed successfully');
    } catch (error) {
      console.error('Error removing member:', error);
      toast.error('Failed to remove member');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleEscape);
    }

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center p-4 z-50 overflow-y-auto">
      <div className="bg-white rounded-lg max-w-2xl w-full p-6 my-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900">
            Manage Members - {group.group_name}
          </h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="mb-6">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search users by email or name..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          {searchResults.length > 0 && (
            <div className="mt-2 border border-gray-200 rounded-lg max-h-40 overflow-y-auto">
              {searchResults.map(user => (
                <div
                  key={user.id}
                  className="flex items-center justify-between p-2 hover:bg-gray-50"
                >
                  <div>
                    <div className="font-medium">{user.firstName} {user.lastName}</div>
                    <div className="text-sm text-gray-500">{user.email}</div>
                  </div>
                  <button
                    onClick={() => addMember(user)}
                    disabled={loading}
                    className="px-3 py-1 bg-indigo-100 text-indigo-700 rounded-lg hover:bg-indigo-200 disabled:opacity-50"
                  >
                    Add
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="border-t border-gray-200 pt-4">
          <h4 className="font-medium text-gray-900 mb-2">
            Current Members ({members.length})
          </h4>
          <div className="space-y-2">
            {members.map(member => (
              <div
                key={member.id}
                className="flex items-center justify-between p-2 bg-gray-50 rounded-lg"
              >
                <div>
                  <div className="font-medium">{member.firstName} {member.lastName}</div>
                  <div className="text-sm text-gray-500">{member.email}</div>
                </div>
                <button
                  onClick={() => removeMember(member)}
                  disabled={loading}
                  className="p-1 text-red-600 hover:text-red-700 disabled:opacity-50"
                >
                  <UserMinus className="w-5 h-5" />
                </button>
              </div>
            ))}
            {members.length === 0 && (
              <p className="text-gray-500 text-center py-4">
                No members in this group yet
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}