import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, orderBy, getDocs, doc, getDoc, addDoc, limit } from 'firebase/firestore';
import { db, getExperiences } from '../../lib/firebase';
import type { Experience } from '../../lib/firebase/types';
import type { Theme } from '../../lib/firebase/types';
import { toast } from 'react-hot-toast';
import ThemeOverview from '../YourStory/ThemeOverview';
import QuestionModal from '../YourStory/QuestionModal';
import ThemeSummaryModal from '../YearInReview/ThemeSummaryModal';
import ExperienceIntro from '../shared/ExperienceIntro';
import LockedThemeModal from '../shared/LockedThemeModal';
import { motion } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';
import { generateStructuredInfo } from '../../lib/openai';
import ZenLoader from '../YearInReview/ZenLoader';
import { FileText, ChevronDown, ChevronUp, Share2 } from 'lucide-react';
import ThemeSummaryViewer from '../shared/ThemeSummaryViewer';
import type { CompletedTheme } from '../../lib/firebase/types';

export default function SharedExperiences() {
  const navigate = useNavigate();
  const { user, userData } = useAuth();
  const [experience, setExperience] = useState<Experience | null>(null);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [loading, setLoading] = useState(true);
  const [showLockedModal, setShowLockedModal] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState<Theme | null>(null);
  const [showThemeOverview, setShowThemeOverview] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [themeContent, setThemeContent] = useState<{ [key: string]: any }>({});
  const [currentDay, setCurrentDay] = useState(1);
  const [showThemeGrid, setShowThemeGrid] = useState(true);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [selectedThemeId, setSelectedThemeId] = useState<string | null>(null);
  const [recentCompletions, setRecentCompletions] = useState<CompletedTheme[]>([]); 
  const [themeTitles, setThemeTitles] = useState<Record<string, string>>({});
  const [expandedThemeId, setExpandedThemeId] = useState<string | null>(null);
  const [isGeneratingExperienceSummary, setIsGeneratingExperienceSummary] = useState(false);
  const [createdFor, setCreatedFor] = useState<string>('');
  const [experienceSummary, setExperienceSummary] = useState<CompletedTheme | null>(null);
  const [hasAllThemesCompleted, setHasAllThemesCompleted] = useState(false);
  const [showNewSummaryViewer, setShowNewSummaryViewer] = useState(false);
  const [currentContent, setCurrentContent] = useState('');

  useEffect(() => {
    const loadExperience = async () => {
      try {
        console.log('Loading shared experiences...');
        setLoading(true);
        const completionsRef = collection(db, 'completedThemes');

        if (user) {
          // Load recent completions
          const completionsQuery = query(
            completionsRef,
            where('userId', '==', user.uid),
            where('experienceId', '==', 'shared-experiences'), // Use route as ID for now
            orderBy('completedAt', 'desc'),
            limit(10)
          );
          const completionsSnapshot = await getDocs(completionsQuery);
          const completions = completionsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as CompletedTheme[];
          setRecentCompletions(completions);
          console.log('Loaded recent completions:', completions);
          setRecentCompletions(completions);
        }

        // Query directly for the shared experiences route
        const experiencesRef = collection(db, 'experiences');
        console.log('Querying for route:', 'shared-experiences');
        
        // First get the experience by route
        const routeQuery = query(experiencesRef, where('route', '==', 'shared-experiences'));
        const routeSnapshot = await getDocs(routeQuery);
        
        if (!routeSnapshot.empty && user) {
          const experienceDoc = routeSnapshot.docs[0];
          const experienceId = experienceDoc.id;
        
          // Update completions query with correct experience ID
          const completionsQuery = query(
            completionsRef,
            where('userId', '==', user.uid),
            where('experienceId', '==', experienceId),
            orderBy('completedAt', 'desc'),
            limit(10)
          );
          const completionsSnapshot = await getDocs(completionsQuery);
          const completions = completionsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as CompletedTheme[];
          setRecentCompletions(completions);
          console.log('Loaded recent completions:', completions);
        }
        
        if (!routeSnapshot.empty) {
          const experienceDoc = routeSnapshot.docs[0];
          console.log('Found experience:', experienceDoc.id);
          const sharedExperience = {
            id: experienceDoc.id,
            ...experienceDoc.data()
          } as Experience;
          
          setExperience(sharedExperience);
          
          // Load themes
          const themesRef = collection(db, 'themes');
          const themesQuery = query(
            themesRef,
            where('experienceId', '==', sharedExperience.id),
            orderBy('order', 'asc')
          );
          const themesSnapshot = await getDocs(themesQuery);
          console.log('Found themes:', themesSnapshot.docs.length);
          console.log('Theme details:', themesSnapshot.docs.map(doc => ({
            id: doc.id,
            title: doc.data().title,
            experienceId: doc.data().experienceId
          })));
          
          const themesData = themesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as Theme[];
          setThemes(themesData);
        } else {
          console.error('No shared experiences found. Query returned empty. Looking for route: shared-experiences');
          toast.error('This experience is not available yet');
          navigate('/');
          return;
        }
      } catch (error) {
        console.error('Error loading shared experiences:', error, error instanceof Error ? error.message : '');
        toast.error('Unable to load experience. Please try again later.');
        navigate('/');
      } finally {
        setLoading(false);
      }
    };

    loadExperience();
  }, [navigate, user]);

  const handleThemeSelect = (theme: Theme) => {
    setSelectedTheme(theme);
    setShowThemeOverview(true);
    setShowThemeGrid(false);
  };

  const handleStartTheme = () => {
    setShowThemeOverview(false);
    if (selectedTheme) {
      const existingContent = themeContent[selectedTheme.id]?.responses || {};
      const completedTheme = themeContent[selectedTheme.id];
      if (completedTheme?.completedAt) {
        setCurrentDay(6);
      } else {
        setCurrentDay(1);
        setCurrentContent('');
      }
    }
    setShowThemeGrid(false);
  };

  const handleGenerateSummary = async (weekContent: { [key: number]: string }) => {
    if (!selectedTheme || !user || !userData) return;
    
    const totalQuestions = selectedTheme.questions.length;
    
    // Validate we have all responses
    if (!weekContent || Object.keys(weekContent).length < totalQuestions) {
      toast.error('Please complete all questions before generating summary');
      return;
    }
    
    console.log('Starting summary generation...', {
      weekContent,
      selectedTheme,
      createdFor
    });
    
    try {
      // Format content for OpenAI
      const content = Object.entries(weekContent)
        .sort(([a], [b]) => parseInt(a) - parseInt(b))
        .map(([day, content]) => {
          const question = selectedTheme.questions.find(q => q.id === parseInt(day));
          return `Question ${day}: ${question?.text}\nAnswer: ${content}`;
        })
        .join('\n\n');

      console.log('Formatted content:', content);

      // Use theme's custom prompt if available, otherwise use default
      const systemPrompt = selectedTheme.systemPrompt || `Create an inspiring summary that highlights key themes, growth, and insights from the user's reflections. Structure the response in markdown format with these sections:

# Theme Journey
A concise narrative overview of the journey, challenges faced, and growth achieved.

## Highlights and Wins
Key accomplishments and positive moments (2-5 bullets)

## Strengths and Skills Used
Core competencies and abilities demonstrated throughout the journey (2-5 bullets)

## Impact of Actions
The effects of decisions and actions on both personal and professional life (2-5 bullets)

## Potential Future Story
A forward-looking paragraph that builds on the insights and growth shown.`;

      const response = await generateStructuredInfo(
        content,
        { model: 'gpt-4', temperature: 0.7 },
        systemPrompt + (createdFor ? `\n\nCreated for: ${createdFor}` : '')
      );

      console.log('Got OpenAI response:', response);

      if (!response?.data) {
        throw new Error('Failed to generate summary. Please try again.');
      }

      const timestamp = new Date().toISOString();
      const completedTheme = {
        userId: user.uid,
        userName: `${userData.firstName} ${userData.lastName}`,
        themeId: selectedTheme.id,
        questions: selectedTheme.questions,
        themeType: 'theme',
        experienceId: experience?.id,
        experienceName: experience?.title,
        responses: Object.entries(weekContent).map(([questionId, content]) => ({
          questionId: parseInt(questionId),
          question: selectedTheme.questions.find(q => q.id === parseInt(questionId))?.text || '',
          content,
          createdAt: timestamp
        })),
        summary: response.data,
        completedAt: timestamp
      };

      // Only add createdFor if it has a value
      if (createdFor?.trim()) {
        completedTheme['createdFor'] = createdFor.trim();
      }

      console.log('Saving completed theme:', completedTheme);

      // Save completed theme
      const docRef = await addDoc(collection(db, 'completedThemes'), completedTheme);
      console.log('Saved completed theme with ID:', docRef.id);

      // Update local state
      setThemeContent(prev => ({
        ...prev,
        [selectedTheme.id]: {
          responses: weekContent,
          summary: response.data,
          completedAt: timestamp
        }
      }));

      toast.success('Theme completed and saved!');
      
      // Show the summary immediately
      setSelectedThemeId(docRef.id);
      setShowNewSummaryViewer(true);
      setShowThemeGrid(false);
      
    } catch (error) {
      console.error('Error generating summary:', error);
      toast.error('Failed to generate summary');
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  const handleSaveResponse = async () => {
    if (!currentContent.trim() || !selectedTheme) return;
    console.log('Starting save response...', { currentDay, currentContent });
    setIsGeneratingSummary(true);
    const totalQuestions = selectedTheme.questions.length;

    try {
      const themeId = selectedTheme.id;
      const updatedTheme = {
        responses: {
          ...(themeContent[themeId]?.responses || {}),
          [currentDay]: currentContent
        }
      };
      
      console.log('Updated theme content:', updatedTheme);
      setThemeContent(prev => ({
        ...prev,
        [themeId]: updatedTheme
      }));

      if (currentDay === totalQuestions) {
        const responses = updatedTheme.responses;
        console.log('Checking responses for summary:', responses);
        if (Object.keys(responses).length === totalQuestions) {
          console.log('All responses present, generating summary...');
          await handleGenerateSummary(responses);
        } else {
          console.log('Missing responses:', {
            expected: totalQuestions,
            actual: Object.keys(responses).length
          });
          toast.error('Please complete all questions before generating summary');
        }
      } else {
        setCurrentDay(prev => prev + 1);
        setCurrentContent('');
      }
    } catch (error) {
      console.error('Error saving response:', error);
      toast.error('Failed to save response');
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-indigo-200 border-t-indigo-600"></div>
          <p className="mt-4 text-indigo-600">Loading experience...</p>
        </div>
      </div>
    );
  }

  if (!experience) {
    return (
      <div className="min-h-screen bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="text-center">
            <h1 className="text-3xl font-bold text-indigo-900 mb-4">Experience Not Found</h1>
            <p className="text-indigo-600 mb-8">
              This experience is not available yet. Please check back later.
            </p>
            <button
              onClick={() => navigate('/')}
              className="px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
            >
              Return Home
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {showThemeGrid ? (
          <>
            <div className="mb-8">
              <h1 className="text-2xl font-bold text-indigo-900 mb-2">{experience.title}</h1>
              <p className="text-indigo-600">{experience.description}</p>
              
              {recentCompletions.length > 0 && (
                <div className="mt-8">
                  <button
                    onClick={() => setExpandedThemeId(expandedThemeId ? null : 'recent')}
                    className="w-full flex items-center justify-between p-4 bg-indigo-50/50 rounded-t-xl shadow-sm hover:bg-indigo-50/70 transition-colors"
                  >
                    <div className="flex items-center space-x-2">
                      <h2 className="text-xl font-semibold text-indigo-900">Shared Summaries</h2>
                      <span className="text-sm text-gray-500">({recentCompletions.length})</span>
                    </div>
                    {expandedThemeId === 'recent' ? (
                      <ChevronUp className="w-5 h-5 text-gray-400" />
                    ) : (
                      <ChevronDown className="w-5 h-5 text-gray-400" />
                    )}
                  </button>
                  
                  {expandedThemeId === 'recent' && (
                    <div className="bg-white rounded-b-xl shadow-sm p-6">
                      <div className="space-y-4">
                        {recentCompletions.map((completion) => {
                          const handleShare = async () => {
                            try {
                              const expirationDate = new Date();
                              expirationDate.setDate(expirationDate.getDate() + 7);
                              
                              const shareData = {
                                summary: completion.summary,
                                createdAt: new Date().toISOString(),
                                expiresAt: expirationDate.toISOString(),
                                userId: user?.uid
                              };
                              
                              const docRef = await addDoc(collection(db, 'publicShares'), shareData);
                              const shareUrl = `${window.location.origin}/share/${docRef.id}`;
                              
                              await navigator.clipboard.writeText(shareUrl);
                              toast.success('Share link copied to clipboard! Link expires in 7 days.');
                            } catch (error) {
                              console.error('Error creating share:', error);
                              toast.error('Failed to create share link');
                            }
                          };

                          return (
                            <motion.div
                              key={completion.id}
                              initial={{ opacity: 0, y: 20 }}
                              animate={{ opacity: 1, y: 0 }}
                              transition={{ duration: 0.3 }}
                              className="bg-white rounded-xl shadow-sm overflow-hidden"
                            >
                              <div className="px-6 py-4 bg-gradient-to-r from-indigo-50 to-blue-50">
                                <div className="flex justify-between items-center">
                                  <div>
                                    <h3 className="text-xl font-semibold text-indigo-900">
                                      {completion.themeTitle || completion.summaryTitle || 'Theme'} Summary
                                      {completion.createdFor && (
                                        <span className="ml-2 text-base text-indigo-600">
                                          (Created for {completion.createdFor})
                                        </span>
                                      )}
                                    </h3>
                                    <p className="text-sm text-gray-600 mt-1">
                                      Completed {new Date(completion.completedAt).toLocaleDateString()}
                                    </p>
                                  </div>
                                  <div className="flex space-x-2">
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleShare();
                                      }}
                                      className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg transition-colors"
                                      title="Share Summary"
                                    >
                                      <Share2 className="w-5 h-5" />
                                    </button>
                                    <button
                                      onClick={() => {
                                        setSelectedThemeId(completion.id);
                                        setShowNewSummaryViewer(true);
                                      }}
                                      className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg transition-colors"
                                    >
                                      <FileText className="w-5 h-5" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </motion.div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {themes.map((theme) => {
                const completedDays = Object.keys(themeContent[theme.id]?.responses || {}).length;
                const totalDays = theme.questions.length;
                const progress = (completedDays / totalDays) * 100;
                const isCompleted = !!themeContent[theme.id]?.completedAt;
                const isEligible = true;
                
                return (
                  <motion.div
                    key={theme.id}
                    whileHover={{ scale: 1.02 }}
                    className="bg-white p-8 rounded-2xl shadow-xl overflow-hidden cursor-pointer border-2 border-indigo-100 hover:shadow-2xl transition-all"
                  >
                    <div className="flex justify-between items-start mb-4">
                      <div>
                        <h3 className="text-xl font-semibold text-indigo-900">{theme.title}</h3>
                        <p className="text-indigo-600 mt-1">{theme.description}</p>
                        <div className="flex flex-wrap gap-3 mt-4">
                          <span className="px-3 py-1 bg-indigo-50 text-indigo-700 rounded-full text-sm">
                            {theme.questions.length} Questions
                          </span>
                        </div>
                        <button
                          onClick={() => setExpandedThemeId(expandedThemeId === theme.id ? null : theme.id)}
                          className="mt-2 text-sm text-indigo-600 hover:text-indigo-700"
                        >
                          {expandedThemeId === theme.id ? 'Hide Blueberries' : 'Preview Blueberries'}
                        </button>
                      </div>
                    </div>

                    {expandedThemeId === theme.id && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        className="mt-2 text-sm text-indigo-600 hover:text-indigo-700"
                      >
                        <div className="space-y-2">
                          {theme.questions.map((question, index) => (
                            <div
                              key={question.id}
                              className="flex items-center space-x-2 text-sm text-gray-600 p-2 rounded-lg hover:bg-indigo-50"
                            >
                              <span className="w-5 h-5 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 font-medium">
                                {index + 1}
                              </span>
                              <span className="flex-1">{question.text}</span>
                            </div>
                          ))}
                        </div>
                      </motion.div>
                    )}

                    <div className="space-y-2">
                      <div className="flex justify-between items-center text-sm">
                        <span className="text-gray-500">
                          {isCompleted ? 'Completed' : `${completedDays}/${totalDays} completed`}
                        </span>
                        <button
                          onClick={() => handleThemeSelect(theme)}
                          className={`text-purple-600 font-medium hover:text-purple-700 ${
                            !isEligible ? 'opacity-50 cursor-not-allowed' : ''
                          }`}
                        >
                          {!isEligible ? 'Complete previous themes first' :
                           isCompleted ? 'View Summary' : 
                           completedDays > 0 ? 'Continue' : 'Start'}
                        </button>
                      </div>
                      <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                        <div
                          className={`h-full transition-all duration-300 ${
                            isCompleted ? 'bg-green-500' : isEligible ? 'bg-indigo-600' : 'bg-gray-300'
                          }`}
                          style={{ width: `${progress}%` }}
                        />
                      </div>
                    </div>
                  </motion.div>
                );
              })}
            </div>
          </>
        ) : (
          <QuestionModal
            theme={selectedTheme}
            currentQuestion={currentDay}
            answers={themeContent[selectedTheme?.id]?.responses || {}}
            onClose={() => setShowThemeGrid(true)}
            onAnswer={(questionId, answer) => {
              if (!selectedTheme) return;
              setThemeContent(prev => ({
                ...prev,
                [selectedTheme.id]: {
                  ...prev[selectedTheme.id],
                  responses: {
                    ...(prev[selectedTheme.id]?.responses || {}),
                    [questionId]: answer
                  }
                }
              }));
              setCurrentContent(answer);
            }}
            onNext={() => setCurrentDay(prev => prev + 1)}
            onPrevious={() => setCurrentDay(prev => prev - 1)}
            onComplete={handleSaveResponse}
            isGeneratingSummary={isGeneratingSummary}
          />
        )}
      </div>
      
      {selectedTheme && (
        <ThemeOverview
          isOpen={showThemeOverview}
          onClose={() => setShowThemeOverview(false)}
          title={selectedTheme.title}
          description={selectedTheme.description}
          introduction={experience.customPrompt || ''}
          showCreatedFor={selectedTheme.showCreatedFor}
          onCreatedForChange={setCreatedFor}
          createdFor={createdFor}
          onStart={handleStartTheme}
        />
      )}
      
      <LockedThemeModal
        isOpen={showLockedModal}
        onClose={() => setShowLockedModal(false)}
      />
      {(isGeneratingSummary) && <ZenLoader />}
      {selectedThemeId && user && (
        <ThemeSummaryViewer
          isOpen={showNewSummaryViewer}
          onClose={() => {
            setShowNewSummaryViewer(false);
            setSelectedThemeId(null);
            setShowThemeGrid(true);
            setSelectedTheme(null);
          }}
          completedThemeId={selectedThemeId}
          userId={user.uid}
        />
      )}
    </div>
  );
}