import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { X, FileText, Download, Share2 } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { PDFGenerator } from '../shared/PDFGenerator';
import { useAuth } from '../../contexts/AuthContext';
import { getCompletedThemes, CompletedTheme, db } from '../../lib/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { getThemeById } from '../../lib/firebase/themes';

import ThemeSummaryViewer from '../shared/ThemeSummaryViewer';

interface DashboardProps {
  onClose?: () => void;
}

export default function Dashboard({ onClose }: DashboardProps) {
  const navigate = useNavigate();
  const { user, userData } = useAuth();
  const [themes, setThemes] = useState<CompletedTheme[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showDebug, setShowDebug] = useState(false);
  const [showOnlyExperienceSummaries, setShowOnlyExperienceSummaries] = useState(false);
  const [selectedThemeId, setSelectedThemeId] = useState<string | null>(null);
  const [showSummaryViewer, setShowSummaryViewer] = useState(false);
  const [themeTitles, setThemeTitles] = useState<Record<string, string>>({});
  const [expandedThemeId, setExpandedThemeId] = useState<string | null>(null);
  const [isGeneratingExperienceSummary, setIsGeneratingExperienceSummary] = useState(false);
  const [experienceSummary, setExperienceSummary] = useState<CompletedTheme | null>(null);
  const [experienceFilter, setExperienceFilter] = useState<string>('all');
  const [hasAllThemesCompleted, setHasAllThemesCompleted] = useState(false);
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [selectedThemeForPDF, setSelectedThemeForPDF] = useState<CompletedTheme | null>(null);

  const handleDownloadPDF = (theme: CompletedTheme) => {
    setSelectedThemeForPDF(theme);
    setShowPDFModal(true);
  };

  const handleViewSummary = (theme: CompletedTheme) => {
    setSelectedThemeId(theme.id);
    setShowSummaryViewer(true);
  };
  
  const handleShare = async (theme: CompletedTheme) => {
    try {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7); // 7 day expiration
      
      const shareData = {
        summary: theme.summary,
        createdAt: new Date().toISOString(),
        expiresAt: expirationDate.toISOString(),
        userId: user?.uid
      };
      
      const docRef = await addDoc(collection(db, 'publicShares'), shareData);
      const shareUrl = `${window.location.origin}/share/${docRef.id}`;
      
      // Copy to clipboard
      await navigator.clipboard.writeText(shareUrl);
      toast.success('Share link copied to clipboard! Link expires in 7 days.');
    } catch (error) {
      console.error('Error creating share:', error);
      toast.error('Failed to create share link');
    }
  };

  useEffect(() => {
    const loadThemes = async () => {
      if (!user) return;

      try {
        setLoading(true);
        setError(null);
        const fetchedThemes = await getCompletedThemes(user.uid);
        
        // Load theme titles for any themes that don't have them
        const themesNeedingTitles = fetchedThemes.filter(theme => !theme.themeTitle);
        if (themesNeedingTitles.length > 0) {
          const titles = await Promise.all(
            themesNeedingTitles.map(async theme => {
              const themeDoc = await getThemeById(theme.themeId);
              return {
                themeId: theme.themeId,
                title: themeDoc?.title || 'Theme'
              };
            })
          );
          
          const titleMap = titles.reduce((acc, { themeId, title }) => {
            acc[themeId] = title;
            return acc;
          }, {} as Record<string, string>);
          
          setThemeTitles(titleMap);
        }

        if (showDebug) {
          console.log('User ID:', user.uid);
          console.log('Fetched themes:', fetchedThemes);
        }
        setThemes(fetchedThemes);
      } catch (error) {
        console.error('Error loading themes:', error);
        setError('Failed to load your completed themes. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    loadThemes();
  }, [user, showDebug]);


  if (loading) {
    return (
      <div className="min-h-screen bg-white">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-indigo-200 border-t-indigo-600 mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading your completed themes...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center space-x-6">
            <h1 className="text-2xl font-bold text-indigo-900">My Completed Themes</h1>
            <select
              value={experienceFilter}
              onChange={(e) => setExperienceFilter(e.target.value)}
              className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            >
              <option value="all">All Experiences</option>
              <option value="year-in-review">Year in Review</option>
              <option value="shared-experiences">Shared Reflections</option>
            </select>
            <button
              onClick={() => setShowOnlyExperienceSummaries(!showOnlyExperienceSummaries)}
              className={`px-4 py-2 rounded-lg text-sm transition-colors ${
                showOnlyExperienceSummaries
                  ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {showOnlyExperienceSummaries ? 'Show All' : 'Show Experience Summaries'}
            </button>
          </div>
          {onClose && (
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              ×
            </button>
          )}
        </div>

        {error && (
          <div className="mb-8 bg-red-50 border border-red-200 rounded-lg p-4">
            <p className="text-red-700">{error}</p>
          </div>
        )}

        {themes.length === 0 ? (
          <div className="text-center py-16 bg-white rounded-xl shadow-sm">
            <div className="mb-4">
              <FileText className="w-12 h-12 text-gray-400 mx-auto" />
            </div>
            <div className="space-y-4">
              <p className="text-gray-600">
                No completed themes yet. Start your journey by completing a theme in{' '}
                <button 
                  onClick={() => navigate('/experience-test')}
                  className="text-indigo-600 hover:text-indigo-700 font-medium"
                >
                  Experience Test
                </button>!
              </p>
              
              {/* Debug toggle for development */}
              <button 
                onClick={() => setShowDebug(!showDebug)}
                className="text-sm text-indigo-400 hover:text-indigo-500"
              >
                Toggle Debug Info
              </button>
              
              {showDebug && (
                <div className="text-left p-4 bg-gray-50 rounded-lg">
                  <p className="text-sm text-gray-500">User ID: {user?.uid}</p>
                  <p className="text-sm text-gray-500">Query Path: completedThemes</p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="space-y-6">
            {themes.filter(theme => {
              if (showOnlyExperienceSummaries) {
                return theme.themeType === 'experience_summary';
              }
              if (experienceFilter !== 'all') {
                if (experienceFilter === 'shared-experiences') {
                  return theme.experienceId === 'shared-experiences' || theme.experienceId === 'FkfQnHvmsEdjfME9n3sS';
                }
                return theme.experienceId === experienceFilter;
              }
              return true;
            })
            .map((theme) => (
              <motion.div
                key={theme.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className={`bg-white rounded-xl shadow-sm overflow-hidden ${
                  theme.themeType === 'experience_summary' ? 'ring-2 ring-indigo-500' : ''
                }`}
              >
                <div className={`px-6 py-4 ${
                  theme.themeType === 'experience_summary' 
                    ? 'bg-gradient-to-r from-indigo-100 to-purple-100'
                    : 'bg-gradient-to-r from-indigo-50 to-blue-50'
                }`}>
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-xs text-gray-500 mb-1">Theme ID: {theme.id}</p>
                      <h3 className="text-xl font-semibold text-indigo-900">
                        {theme.themeType === 'experience_summary' ? 'Year In Review Summary' : `${theme.themeTitle || themeTitles[theme.themeId] || 'Theme'} Summary`}
                        {theme.createdFor && (
                          <span className="ml-2 text-base text-indigo-600">
                            (Created for {theme.createdFor})
                          </span>
                        )}
                      </h3>
                      {theme.themeType === 'experience_summary' && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                          Experience Summary
                        </span>
                      )}
                      <p className="text-sm text-indigo-600 mt-1">
                        {typeof theme.description === 'string' ? theme.description : 'Theme reflection and insights'}
                      </p>
                    </div>
                    <div className="flex space-x-2">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleShare(theme);
                        }}
                        className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg transition-colors"
                        title="Share Summary"
                      >
                        <Share2 className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => {
                          handleViewSummary(theme);
                        }} 
                        className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg transition-colors"
                      >
                        <FileText className="w-5 h-5" />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDownloadPDF(theme);
                        }}
                        className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg transition-colors"
                      >
                        <Download className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                  <p className="text-sm text-gray-600 mt-1">
                    {theme.completedAt && `Completed on ${new Date(theme.completedAt).toLocaleDateString()}`}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        )}

        {selectedThemeId && user && (
          <ThemeSummaryViewer
            isOpen={showSummaryViewer}
            onClose={() => {
              setShowSummaryViewer(false);
              setSelectedThemeId(null);
            }}
            completedThemeId={selectedThemeId}
            userId={user.uid}
          />
        )}
        
        {selectedThemeForPDF && (
          <PDFGenerator
            isOpen={showPDFModal}
            onClose={() => {
              setShowPDFModal(false);
              setSelectedThemeForPDF(null);
            }}
            title={selectedThemeForPDF.themeType === 'experience_summary' ? 'Year In Review' : (selectedThemeForPDF.themeTitle || selectedThemeForPDF.summaryTitle || 'Theme')}
            content={selectedThemeForPDF.summary}
            createdFor={selectedThemeForPDF.createdFor}
            responses={selectedThemeForPDF.responses?.map(r => ({
              question: r.question,
              content: r.content
            }))}
          />
        )}
      </div>
    </div>
  );
}