import React, { useEffect, useRef } from 'react';
import { AccessLevel } from '../enums/AccessLevel';
import { motion } from 'framer-motion';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowRight, Route, Users, CalendarDays, MapPin } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import SuccessBanner from './shared/SuccessBanner';
import { HeroSection } from './landing/HeroSection';
import { PainSection } from './landing/PainSection';
import { BenefitsSection } from './landing/BenefitsSection';
import { HowItWorksSection } from './landing/HowItWorksSection';
import { WhyChooseSection } from './landing/WhyChooseSection';
import { EarlyAdopterSection } from './landing/EarlyAdopterSection';
import { TeamsSection } from './landing/TeamsSection';
import { CtaSection } from './landing/CtaSection';
import { doc, updateDoc, addDoc, collection, arrayUnion, getDocs, query, where } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { sleep } from 'openai/core.mjs';

export default function Landing() {
  const isMounted = useRef(false);
  const hasStartedOperation = useRef(false);

  const navigate = useNavigate();
  const { user, dispatch, userData } = useAuth();
  const [searchParams] = useSearchParams();
  const showSuccessBanner = searchParams.get('success') === 'true';

  useEffect(() => {
    isMounted.current = true;

    if (searchParams.get('success') === 'true' && !hasStartedOperation.current) {
      console.log(`Calling Handling linking user...`);
      handleLinkingUser(user.uid);
    }

    return () => {
      isMounted.current = false;
    };
  }, [user, searchParams]); // Only re-run when user or searchParams change

  //TODO: don not allow group Admins to be deleted from the group members.
  const createGroup = async (userId: string, totalSeats: number, startDate: Date, endDate: Date, groupName: string) => {
    try {
      console.log('Creating group ' + groupName);
      const groupCode = await generateGroupCode();
      const newGroup = {
        group_name: groupName,
        group_code: groupCode,
        total_seats: totalSeats,
        seats_used: 1, // Add Admin to group
        seats_remaining: totalSeats - 1, // Subtract 1 for the Admin
        members: [userId], // Add Admin to group 
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        created_at: new Date().toISOString(),
        groupAdmin: userId
      };

      return await addDoc(collection(db, 'groups'), newGroup);
    } catch (error) {
      console.error('Error creating group:', error);
      return null;
    } 
  };
  
  const updateUserDoc = async (userId: string, userInfo: any ) => {
    console.log('Updating user document: ' + userId);
    console.log('User Info: ', userInfo.stringify);
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      access_level: userInfo.access_level,
      membership_start: userInfo.membership_start.toISOString(),
      membership_end: userInfo.membership_end.toISOString(),
      subscription_id: userInfo.subscription_id,
      stripe_customer_id: userInfo.stripe_customer_id,
    });
    
    if (userInfo.group_admin_for) {
      await updateDoc(doc(db, 'users', userId), {
        group_admin_for: arrayUnion(userInfo.group_admin_for)
      });
    }
  };

  const generateGroupCode = async (): Promise<string> => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    let isUnique = false;

    while (!isUnique) {
      result = '';
      for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
      isUnique = await verifyGroupCodeUnique(result);
    }

    return result;
  };

  const verifyGroupCodeUnique = async (groupCode: string): Promise<boolean> => {
    const groupsRef = collection(db, 'groups');
    const q = query(groupsRef, where('group_code', '==', groupCode));
    const snapshot = await getDocs(q);
    return snapshot.empty;
  };

  const getQuantityFromSession = (sessionParam: string | null): number => {
    if (!sessionParam) return 1;
    const quantity = parseInt(sessionParam, 10);
    return isNaN(quantity) ? 1 : quantity;
  };
  

  const handleLinkingUser = async (userId: string) => {
    if (hasStartedOperation.current) {
      console.log("Already processing");
      return;
    }
    if (!searchParams.get('success')) {
      console.log("no success param");
      return;
    }

    try { 
      hasStartedOperation.current = true;
      console.log(`Processing new subscription... mounted:${isMounted.current} hasStarted:${hasStartedOperation.current}`);
      const sessionID = searchParams.get('s');
      const accessLevel = searchParams.get('g') ? AccessLevel.GroupMembership : AccessLevel.PersonalMembership;
      const totalSeats = getQuantityFromSession(searchParams.get('q'))

      const response = await fetch('/api/handle-stripe-subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ sessionId: sessionID }),
      });

      if (!response.ok) throw new Error('Failed to process subscription: ' + sessionID);

      const { subscriptionId, stripeCustomerId } = await response.json();
      const startDate = new Date();
      const endDate = new Date(startDate);
      endDate.setFullYear(endDate.getFullYear() + 1);

      // Create group if needed
      let groupForId = '';
      if (totalSeats > 1) {
        console.log("creating new group");
        const groupName = 'Group ' + userData?.lastName;
        const groupDoc = await createGroup(userId, totalSeats, startDate, endDate, groupName);
        groupForId = groupDoc.id; //TODO: check if groupDoc is null
      }

      const userInfo = {
        access_level: accessLevel,
        membership_start:  startDate,
        membership_end: endDate,
        subscription_id: subscriptionId,
        stripe_customer_id: stripeCustomerId,
        group_admin_for: groupForId
      }

      await updateUserDoc(userId, userInfo);
      dispatch({ type: 'UPDATE_ACCESS_LEVEL', payload: accessLevel });

      // Remove search parameters from the URL
      const newUrl = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
      if (groupForId !== '') {
        sleep(1000);
        navigate('/admin/group-admin', { replace: true });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error processing subscription: ', error.message);
      } else {
        console.error('Unknown error processing subscription: ', error);
      }
    } finally {
      console.log('Finished processing subscription.');
      if (isMounted.current) {
        hasStartedOperation.current = false;
      }
    }
  }

  if (user) {
    return (   
      <>
        {showSuccessBanner && <SuccessBanner />}
        <div className="min-h-screen bg-gradient-to-b from-sky-100 to-indigo-50">
        <div className="flex flex-col items-center justify-center min-h-[75vh] p-4">
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="text-center max-w-4xl w-full"
          >
            <motion.div
              initial={{ y: 0 }}
              animate={{ y: [0, -10, 0] }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              className="relative mb-6"
            >
              <div className="absolute inset-0 blur-xl bg-blue-200/50 rounded-full" />
              <div className="relative text-8xl md:text-9xl">
                🫐
              </div>
            </motion.div>

            <h1 className="text-4xl md:text-5xl font-bold text-indigo-900 mb-4">
              Finding Blueberries
            </h1>
            
            <p className="text-lg text-indigo-700 mb-8 max-w-md mx-auto">
              A delightful journey to discover your path to success.
            </p>

            <div className="flex flex-col items-center space-y-4">
              <motion.button
                onClick={() => navigate('/clarity-kick-start')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="group inline-flex items-center space-x-4 px-8 py-4 bg-white border-2 border-indigo-600 text-indigo-600 rounded-xl text-xl font-semibold shadow-lg hover:bg-indigo-50 transition-all duration-200 w-full justify-center"
              >
                <MapPin className="w-6 h-6 transition-transform group-hover:rotate-6" />
                <span>Unsure? Start Here!</span>
                <ArrowRight className="w-6 h-6 transition-transform group-hover:translate-x-1" />
              </motion.button>
              
              <motion.button
                onClick={() => navigate('/deeper-reflections')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="group inline-flex items-center space-x-4 px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl text-xl font-semibold shadow-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-200 w-full justify-center"
              >
                <Route className="w-6 h-6 transition-transform group-hover:rotate-6" />
                <span>Deeper Reflections</span>
                <ArrowRight className="w-6 h-6 transition-transform group-hover:translate-x-1" />
              </motion.button>
              
              <motion.button
                onClick={() => navigate('/shared-experiences')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="group inline-flex items-center space-x-4 px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl text-xl font-semibold shadow-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-200 w-full justify-center"
              >
                <Users className="w-6 h-6 transition-transform group-hover:rotate-6" />
                <span>Shared Reflections</span>
                <ArrowRight className="w-6 h-6 transition-transform group-hover:translate-x-1" />
              </motion.button>
              
              <motion.button
                onClick={() => navigate('/daily-reflection')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="group inline-flex items-center space-x-4 px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl text-xl font-semibold shadow-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-200 w-full justify-center"
              >
                <CalendarDays className="w-6 h-6 transition-transform group-hover:rotate-6" />
                <span>Daily Reflection</span>
                <ArrowRight className="w-6 h-6 transition-transform group-hover:translate-x-1" />
              </motion.button>
            </div>
          </motion.div>
        </div>
       </div>
      </>
    );
  }

  return (
    <>
      {showSuccessBanner && <SuccessBanner />}
      <HeroSection onSignUp={() => navigate('/login')} />
      <PainSection />
      <BenefitsSection onSignUp={() => navigate('/login')} />
      <HowItWorksSection />
      <WhyChooseSection onSignUp={() => navigate('/login')} />
      <EarlyAdopterSection />
      <TeamsSection />
      <CtaSection onSignUp={() => navigate('/login')} />
    </>
  );
}