import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Download } from 'lucide-react';
import { jsPDF } from 'jspdf';

interface PDFGeneratorProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  content: string;
  createdFor?: string;
  responses?: Array<{
    question: string;
    content: string;
  }>;
}

interface PDFContent {
  type: 'h1' | 'h2' | 'p' | 'bullet';
  text: string;
}

function parseMarkdownToPDFContent(markdown: string): PDFContent[] {
  return markdown.split('\n')
    .map(line => {
      const trimmed = line.trim();
      if (!trimmed) return null;
      if (trimmed.startsWith('# ')) return { type: 'h1', text: trimmed.substring(2) };
      if (trimmed.startsWith('## ')) return { type: 'h2', text: trimmed.substring(3) };
      if (trimmed.startsWith('- ')) return { type: 'bullet', text: trimmed.substring(2) };
      return { type: 'p', text: trimmed };
    })
    .filter(Boolean) as PDFContent[];
}

export function PDFGenerator({
  isOpen,
  onClose,
  title,
  content,
  createdFor,
  responses
}: PDFGeneratorProps) {
  const [includeResponses, setIncludeResponses] = React.useState(false);

  const generatePDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 15;
    let yPos = margin;

    // Helper function for text wrapping and positioning
    const addText = (text: string, fontSize: number, color: number[], align: 'left' | 'center' = 'left', indent = 0) => {
      doc.setFontSize(fontSize);
      doc.setTextColor(...color);
      const maxWidth = pageWidth - (2 * margin) - indent;
      const lines = doc.splitTextToSize(text, maxWidth);
      const x = align === 'center' ? pageWidth / 2 : margin + indent;
      doc.text(lines, x, yPos);
      return lines.length * (fontSize * 0.352778); // Convert pt to mm
    };

    // Main title (larger and in center)
    doc.setFontSize(24);
    doc.setTextColor(79, 70, 229); // Main title in purple
    const displayTitle = title === 'Theme' ? content.split('\n')[0].replace(/^#\s*/, '') : title;
    doc.text(displayTitle, pageWidth / 2, yPos, { align: 'center' });
    yPos += 15;

    // Created For
    if (createdFor) {
      doc.setFontSize(12);
      doc.setTextColor(128, 128, 128);
      doc.text(`Created for: ${createdFor}`, pageWidth / 2, yPos, { align: 'center' });
      yPos += 20;
    }

    // Content
    const parsedContent = parseMarkdownToPDFContent(content);
    let skipFirstTitle = true;
    
    parsedContent.forEach(item => {
      if (skipFirstTitle && item.type === 'h1') {
        skipFirstTitle = false;
        return;
      }
      
      switch (item.type) {
        case 'h1':
          yPos += 5;
          doc.setFontSize(20);
          doc.setTextColor(31, 41, 55); // Darker gray for headings
          doc.text(item.text, margin, yPos);
          yPos += 8;
          break;
        case 'h2':
          yPos += 5;
          doc.setFontSize(16);
          doc.setTextColor(31, 41, 55); // Darker gray for subheadings
          doc.text(item.text, margin, yPos);
          yPos += 8;
          break;
        case 'bullet':
          doc.setFontSize(10);
          doc.text('•', margin, yPos);
          yPos += addText(item.text, 10, [31, 41, 55], 'left', 5); // Darker gray text
          yPos += 5;
          break;
        case 'p':
          yPos += addText(item.text, 12, [31, 41, 55]); // Darker gray text
          yPos += 5;
          break;
      }

      if (yPos > doc.internal.pageSize.getHeight() - margin) {
        doc.addPage();
        yPos = margin;
      }
    });

    // Add responses if checkbox is checked
    if (includeResponses && responses) {
      doc.addPage();
      yPos = margin;
      
      doc.setFontSize(16);
      doc.setTextColor(31, 41, 55);
      doc.text("Original Questions & Answers", margin, yPos);
      yPos += 10;

      responses.forEach((response, index) => {
        doc.setFontSize(12);
        doc.setTextColor(79, 70, 229); // Question in indigo
        const questionLines = doc.splitTextToSize(`Q${index + 1}: ${response.question}`, pageWidth - (2 * margin));
        doc.text(questionLines, margin, yPos);
        yPos += questionLines.length * 5;

        doc.setTextColor(31, 41, 55); // Answer in dark gray
        const answerLines = doc.splitTextToSize(`A: ${response.content}`, pageWidth - (2 * margin));
        doc.text(answerLines, margin, yPos);
        yPos += answerLines.length * 5 + 8;

        // Add page if needed
        if (yPos > doc.internal.pageSize.getHeight() - margin) {
          doc.addPage();
          yPos = margin;
        }
      });
    }

    // Footer
    doc.setFontSize(8);
    doc.setTextColor(128, 128, 128);
    doc.text('Visit findingblueberries.com', pageWidth / 2, doc.internal.pageSize.getHeight() - 10, { align: 'center' });

    const fileName = `${title.toLowerCase().replace(/\s+/g, '-')}-${new Date().toISOString().split('T')[0]}.pdf`;
    doc.save(fileName);
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black"
            onClick={onClose}
          />
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="fixed inset-0 flex items-center justify-center p-4 z-50"
          >
            <div className="bg-white rounded-xl max-w-md w-full p-6 relative">
              <button
                onClick={onClose}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
              >
                <X className="w-5 h-5" />
              </button>

              <div className="text-center space-y-6">
                <h2 className="text-2xl font-bold text-gray-900">Download Summary</h2>
                <p className="text-gray-600">
                  Your summary will be downloaded as a PDF.
                </p>
                {responses && responses.length > 0 && (
                  <div className="flex items-center justify-center space-x-2">
                    <input
                      type="checkbox"
                      id="includeResponses"
                      checked={includeResponses}
                      onChange={(e) => setIncludeResponses(e.target.checked)}
                      className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                    />
                    <label htmlFor="includeResponses" className="text-sm text-gray-600">
                      Include original questions and answers
                    </label>
                  </div>
                )}
                <button
                  onClick={generatePDF}
                  className="flex items-center justify-center space-x-2 w-full px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
                >
                  <Download className="w-5 h-5" />
                  <span>Download PDF</span>
                </button>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}