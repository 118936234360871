
import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../../../components/ui/Card";
import { Button } from "../../../components/ui/Button";
import { PlayCircle, AlertCircle, CheckCircle, Plus } from 'lucide-react';
import UserTypesModal from './UserTypesModal';
import { collection, addDoc, getDocs, query } from 'firebase/firestore';
import { db } from '../../../lib/firebase';

interface Test {
  id: string;
  title: string;
  code: string;
}

interface TestRunnerProps {
  environments: { [key: string]: string };
}

const TestRunner: React.FC<TestRunnerProps> = ({ environments }) => {
  const [selectedEnv, setSelectedEnv] = useState('production');
  const [selectedUserType, setSelectedUserType] = useState('SUPER_ADMIN');
  const [isRunning, setIsRunning] = useState<string | null>(null);
  const [testResults, setTestResults] = useState<{[key: string]: any}>({});
  const [expandedLogs, setExpandedLogs] = useState<{[key: string]: boolean}>({});
  const [showUserTypesModal, setShowUserTypesModal] = useState(false);
  const [tests, setTests] = useState<Test[]>([]);
  const [showAddTest, setShowAddTest] = useState(false);
  const [newTest, setNewTest] = useState({ title: '', code: '' });
  const [expandedTests, setExpandedTests] = useState<{[key: string]: boolean}>({});

  useEffect(() => {
    fetchTests();
  }, []);

  const fetchTests = async () => {
    try {
      const testsRef = collection(db, 'tests');
      const snapshot = await getDocs(query(testsRef));
      const testData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as Test));
      setTests(testData);
    } catch (error) {
      console.error('Error fetching tests:', error);
    }
  };

  const handleRunTest = async (testId: string) => {
    try {
      setIsRunning(testId);
      const test = tests.find(t => t.id === testId);
      if (!test) return;

      const context = {
        environment: environments[selectedEnv],
        userType: selectedUserType
      };

      try {
        const testFunction = new Function('context', test.code);
        const logs: string[] = [];
        const addLog = (message: string) => logs.push(`[${new Date().toISOString()}] ${message}`);
        
        addLog('Starting test execution');
        addLog(`Environment: ${context.environment}`);
        addLog(`User Type: ${context.userType}`);
        
        const result = await testFunction({...context, log: addLog});
        addLog('Test execution completed successfully');
        
        setTestResults(prev => ({
          ...prev,
          [testId]: {
            success: true,
            message: 'Test passed successfully',
            logs: logs
          }
        }));
      } catch (error) {
        setTestResults(prev => ({
          ...prev,
          [testId]: {
            success: false,
            message: error.message,
            logs: []
          }
        }));
      }
    } catch (error) {
      console.error('Error running test:', error);
    } finally {
      setIsRunning(null);
    }
  };

  const handleAddTest = async () => {
    try {
      const testsRef = collection(db, 'tests');
      await addDoc(testsRef, {
        title: newTest.title,
        code: newTest.code
      });
      setNewTest({ title: '', code: '' });
      setShowAddTest(false);
      fetchTests();
    } catch (error) {
      console.error('Error adding test:', error);
    }
  };

  const toggleTestExpansion = (testId: string) => {
    setExpandedTests(prev => ({
      ...prev,
      [testId]: !prev[testId]
    }));
  };

  return (
    <div className="space-y-4">
      <Card>
        <CardHeader>
          <CardTitle>Run Tests</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 gap-6 mb-4">
            <div>
              <label className="block text-sm font-medium mb-2">Environment</label>
              <div className="space-y-2">
                {Object.entries(environments || {}).map(([name, url]) => (
                  <label key={name} className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="environment"
                      value={name}
                      checked={selectedEnv === name}
                      onChange={(e) => setSelectedEnv(e.target.value)}
                      className="text-blue-600"
                    />
                    <span className="capitalize">{name}</span>
                  </label>
                ))}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Test User</label>
              <div className="space-y-2">
                {['SUPER_ADMIN', 'GROUP_ADMIN', 'PERSONAL_USER', 'FREE_USER'].map(type => (
                  <label key={type} className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="userType"
                      value={type}
                      checked={selectedUserType === type}
                      onChange={(e) => setSelectedUserType(e.target.value)}
                      className="text-blue-600"
                    />
                    <span>{type.split('_').join(' ')}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>

          <Button 
            onClick={() => setShowUserTypesModal(true)}
            variant="outline"
            className="w-full mb-4"
          >
            View/Edit User Info
          </Button>

          <div className="border-t pt-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium">Tests</h3>
              <div className="flex gap-2">
                <Button 
                  onClick={() => tests.forEach(test => handleRunTest(test.id))} 
                  size="sm"
                  variant="outline"
                  className="flex items-center gap-2"
                >
                  <PlayCircle className="w-4 h-4" />
                  Run All Tests
                </Button>
                <Button onClick={() => setShowAddTest(true)} size="sm">
                  <Plus className="w-4 h-4 mr-2" />
                  Add Test
                </Button>
              </div>
            </div>

            {showAddTest && (
              <div className="bg-gray-50 p-4 rounded-lg mb-4">
                <input
                  type="text"
                  placeholder="Test title"
                  value={newTest.title}
                  onChange={(e) => setNewTest(prev => ({ ...prev, title: e.target.value }))}
                  className="w-full mb-2 p-2 border rounded"
                />
                <textarea
                  placeholder="Test code"
                  value={newTest.code}
                  onChange={(e) => setNewTest(prev => ({ ...prev, code: e.target.value }))}
                  className="w-full mb-2 p-2 border rounded h-32 font-mono"
                />
                <div className="flex justify-end gap-2">
                  <Button variant="outline" onClick={() => setShowAddTest(false)}>Cancel</Button>
                  <Button onClick={handleAddTest}>Save Test</Button>
                </div>
              </div>
            )}

            <div className="space-y-4">
              {tests.map(test => (
                <div key={test.id} className="border rounded-lg p-4">
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center gap-4">
                      <h4 className="font-medium">{test.title}</h4>
                      <Button
                        onClick={() => toggleTestExpansion(test.id)}
                        size="sm"
                        variant="ghost"
                      >
                        {expandedTests[test.id] ? 'Hide Code' : 'View Code'}
                      </Button>
                    </div>
                    <Button 
                      onClick={() => handleRunTest(test.id)} 
                      size="sm"
                      variant="outline"
                      className="flex items-center gap-2"
                    >
                      <PlayCircle className="w-4 h-4" />
                      Run Test
                    </Button>
                  </div>
                  {expandedTests[test.id] && (
                    <pre className="bg-gray-50 p-3 rounded text-sm mb-2 overflow-x-auto">
                      {test.code}
                    </pre>
                  )}
                  {testResults[test.id] && (
                    <div className="mt-2">
                      <div className={`p-2 rounded ${
                        testResults[test.id].success ? 'bg-green-100' : 'bg-red-100'
                      }`}>
                        {testResults[test.id].message}
                      </div>
                      {testResults[test.id].logs && (
                        <div className="mt-2">
                          <Button
                            onClick={() => setExpandedLogs(prev => ({...prev, [test.id]: !prev[test.id]}))}
                            size="sm"
                            variant="ghost"
                          >
                            {expandedLogs[test.id] ? 'Hide Details' : 'Show Details'}
                          </Button>
                          {expandedLogs[test.id] && (
                            <pre className="mt-2 p-3 bg-gray-50 rounded text-sm font-mono whitespace-pre-wrap">
                              {testResults[test.id].logs.map((log: string, index: number) => (
                                <div key={index} className="py-1">
                                  {log}
                                </div>
                              ))}
                            </pre>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>

      <UserTypesModal
        isOpen={showUserTypesModal}
        onClose={() => setShowUserTypesModal(false)}
      />
    </div>
  );
};

export default TestRunner;
