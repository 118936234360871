import React from 'react';
import { Mail } from 'lucide-react';

export default function TermsOfService() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="space-y-8">
          <h1 className="text-3xl font-bold text-purple-900 mb-8">Terms of Service</h1>
          
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">1. Acceptance of Terms</h2>
            <p>
              By accessing or using the Finding Blueberries application ("the App"), you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use the App.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">2. Description of Service</h2>
            <p>
              Finding Blueberries is an application that allows users to input questions, which are then processed and summarized using OpenAI's language models. The summaries provided are for informational purposes only and should not be considered professional advice.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">3. User Conduct</h2>
            <p>
              You agree to use the App only for lawful purposes and in a manner that does not infringe upon the rights of others or restrict their use and enjoyment of the App. Prohibited activities include, but are not limited to:
            </p>
            <ul className="mt-4 ml-6 space-y-2 list-disc">
              <li>
                Submitting content that is unlawful, harmful, threatening, abusive, defamatory, or otherwise objectionable.
              </li>
              <li>
                Attempting to interfere with the operation of the App or its associated servers and networks.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">4. Privacy and Data Security</h2>
            <p>
              We are committed to protecting your privacy and ensuring the security of your data. When you submit questions through the App, the information is transmitted to OpenAI for processing. While we take reasonable measures to safeguard your data, we cannot guarantee absolute security. By using the App, you acknowledge and accept this risk.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">5. Use of OpenAI Services</h2>
            <p>
              Our App utilizes OpenAI's API to generate summaries based on user input. By using the App, you also agree to comply with OpenAI's Terms of Use, which can be found here:{' '}
              <a href="https://openai.com/policies/terms-of-use" target="_blank" rel="noopener noreferrer" className="text-purple-600 hover:text-purple-700">
                OpenAI Platform
              </a>
              .
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">6. Intellectual Property</h2>
            <p>
              All content available through the App, including but not limited to text, graphics, logos, and software, is the property of Finding Blueberries or its content suppliers and is protected by applicable intellectual property laws. Unauthorized use of any content may violate copyright, trademark, and other laws.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">7. Limitation of Liability</h2>
            <p>
              The summaries provided by the App are generated by an AI model and may not always be accurate or complete. We do not warrant the accuracy, completeness, or usefulness of the information provided. In no event shall Finding Blueberries be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the App.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">8. Modifications to the Terms</h2>
            <p>
              We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the updated Terms within the App. Your continued use of the App after any such changes constitutes your acceptance of the new Terms.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">9. Termination</h2>
            <p>
              We reserve the right to terminate or suspend your access to the App, without prior notice or liability, for any reason, including but not limited to a breach of these Terms.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">10. Contact Information</h2>
            <p>
              If you have any questions or concerns about these Terms, please contact us at:
            </p>
            <a 
              href="mailto:brian@findinggood.com"
              className="inline-flex items-center space-x-2 text-purple-600 hover:text-purple-700"
            >
              <Mail className="w-4 h-4" />
              <span>brian@findinggood.com</span>
            </a>
          </section>

          <div className="text-sm text-gray-600 border-t border-gray-200 pt-8 mt-12">
            By using the Finding Blueberries App, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
          </div>
        </div>
      </div>
    </div>
  );
}