import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, MessageCircle, ChevronDown, ChevronUp, Download, Share2 } from 'lucide-react';
import { collection, addDoc } from 'firebase/firestore';
import { doc, getDoc, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import ReactMarkdown from 'react-markdown';
import { db } from '../../lib/firebase';
import { toast } from 'react-hot-toast';
import { PDFGenerator } from './PDFGenerator';
import type { CompletedTheme } from '../../lib/firebase/types';

interface ThemeSummaryViewerProps {
  isOpen: boolean;
  onClose: () => void;
  completedThemeId: string; 
  userId: string; 
}

export default function ThemeSummaryViewer({
  isOpen,
  onClose,
  completedThemeId,
  userId
}: ThemeSummaryViewerProps) {
  const [loading, setLoading] = React.useState(true);
  const [showResponses, setShowResponses] = React.useState(false);
  const [completedTheme, setCompletedTheme] = React.useState<CompletedTheme | null>(null);
  const [showPDFModal, setShowPDFModal] = React.useState(false);

  const handleShare = async () => {
    if (!completedTheme) return;
    
    try {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);
      
      const shareData = {
        summary: completedTheme.summary,
        createdAt: new Date().toISOString(),
        expiresAt: expirationDate.toISOString(),
        userId
      };
      
      const docRef = await addDoc(collection(db, 'publicShares'), shareData);
      const shareUrl = `${window.location.origin}/share/${docRef.id}`;
      
      await navigator.clipboard.writeText(shareUrl);
      toast.success('Share link copied to clipboard! Link expires in 7 days.');
    } catch (error) {
      console.error('Error creating share:', error);
      toast.error('Failed to create share link');
    }
  };

  React.useEffect(() => {
    const loadTheme = async () => {
      if (!isOpen || !completedThemeId || !userId) return;
      
      try {
        setLoading(true);
        
        // Load directly by completed theme ID
        const docRef = doc(db, 'completedThemes', completedThemeId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setCompletedTheme({ id: docSnap.id, ...docSnap.data() } as CompletedTheme);
        } else {
          toast.error('Unable to load theme summary');
          onClose();
        }
        
      } catch (error) {
        console.error('Error loading theme summary:', error);
        toast.error('Failed to load theme summary');
        onClose();
      } finally {
        setLoading(false);
      }
    };

    loadTheme();
  }, [isOpen, completedThemeId, userId, onClose]);

  if (!isOpen || !completedTheme) return null;

  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <>
          <motion.div
            key={`overlay-${completedThemeId}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black"
            onClick={onClose}
          />
          <motion.div
            key={`modal-${completedThemeId}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="fixed inset-0 flex items-center justify-center p-4 z-50"
          >
            <div className="bg-white rounded-xl max-w-4xl w-full p-8 relative max-h-[90vh] overflow-y-auto">
              {loading ? (
                <div className="flex justify-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600" />
                </div>
              ) : (
                <>
                  <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-900">
                      {completedTheme.themeTitle || 'Theme Summary'}
                    </h2>
                    <div className="flex items-center space-x-4">
                      <button
                        onClick={handleShare}
                        className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg"
                      >
                        <Share2 className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => setShowPDFModal(true)}
                        className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg"
                      >
                        <Download className="w-5 h-5" />
                      </button>
                      <button
                        onClick={onClose}
                        className="p-2 text-gray-400 hover:text-gray-600"
                      >
                        <X className="w-5 h-5" />
                      </button>
                    </div>
                  </div>

                  <button
                    onClick={() => setShowResponses(!showResponses)}
                    className="w-full flex items-center justify-between p-4 bg-purple-50 rounded-lg hover:bg-purple-100 mb-6"
                  >
                    <div className="flex items-center space-x-2">
                      <MessageCircle className="w-5 h-5 text-purple-600" />
                      <span className="font-medium text-purple-900">Your Responses</span>
                    </div>
                    {showResponses ? (
                      <ChevronUp className="w-5 h-5 text-purple-600" />
                    ) : (
                      <ChevronDown className="w-5 h-5 text-purple-600" />
                    )}
                  </button>

                  {showResponses && (
                    <div className="mb-6 space-y-4 bg-white p-6 rounded-lg border border-purple-100">
                      {completedTheme.responses.map((response) => (
                        <div key={response.questionId} className="space-y-2">
                          <p className="font-medium text-purple-900">{response.question}</p>
                          <p className="text-gray-600 whitespace-pre-wrap">{response.content}</p>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="prose max-w-none">
                    <ReactMarkdown>{completedTheme.summary}</ReactMarkdown>
                  </div>
                </>
              )}
            </div>
          </motion.div>
        </>
      )}

      {completedTheme && (
        <PDFGenerator
          isOpen={showPDFModal}
          onClose={() => setShowPDFModal(false)}
          title={completedTheme.themeTitle || 'Theme Summary'}
          content={completedTheme.summary}
          createdFor={completedTheme.createdFor}
          responses={completedTheme.responses.map(r => ({
            question: r.question,
            content: r.content
          }))}
        />
      )}
    </AnimatePresence>
  );
}