
import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../../lib/firebase';
import { toast } from 'react-hot-toast';
import { Button } from '../../../components/ui/Button';

interface UserType {
  type: string;
  email: string;
  password: string;
}

interface UserTypesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const defaultUserTypes = {
  SUPER_ADMIN: { type: 'SUPER_ADMIN', email: '', password: '' },
  GROUP_ADMIN: { type: 'GROUP_ADMIN', email: '', password: '' },
  PERSONAL_USER: { type: 'PERSONAL_USER', email: '', password: '' },
  FREE_USER: { type: 'FREE_USER', email: '', password: '' }
};

export default function UserTypesModal({ isOpen, onClose }: UserTypesModalProps) {
  const [userTypes, setUserTypes] = useState<Record<string, UserType>>(defaultUserTypes);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchUserTypes();
    }
  }, [isOpen]);

  const fetchUserTypes = async () => {
    try {
      const docRef = doc(db, 'testConfig', 'userTypes');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUserTypes(docSnap.data() as Record<string, UserType>);
      }
    } catch (error) {
      console.error('Error fetching user types:', error);
      toast.error('Failed to load user types');
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const docRef = doc(db, 'testConfig', 'userTypes');
      await setDoc(docRef, userTypes);
      toast.success('User types saved successfully');
      onClose();
    } catch (error) {
      console.error('Error saving user types:', error);
      toast.error('Failed to save user types');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full p-6 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold">User Type Information</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-6">
          {Object.entries(userTypes).map(([type, user]) => (
            <div key={type} className="bg-gray-50 p-4 rounded-lg">
              <h3 className="font-medium mb-3">{type.split('_').join(' ')}</h3>
              <div className="space-y-3">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    value={user.email}
                    onChange={(e) => setUserTypes(prev => ({
                      ...prev,
                      [type]: { ...prev[type], email: e.target.value }
                    }))}
                    className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter email"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Password
                  </label>
                  <input
                    type="text"
                    value={user.password}
                    onChange={(e) => setUserTypes(prev => ({
                      ...prev,
                      [type]: { ...prev[type], password: e.target.value }
                    }))}
                    className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter password"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end gap-3 mt-6 pt-4 border-t">
          <Button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 bg-gray-100 hover:bg-gray-200"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            disabled={loading}
            className="px-4 py-2 bg-blue-600 text-white hover:bg-blue-700"
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </Button>
        </div>
      </div>
    </div>
  );
}
