import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, orderBy, getDocs, addDoc, limit } from 'firebase/firestore';
import { FileText } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../lib/firebase';
import { toast } from 'react-hot-toast';
import type { Experience, Theme, CompletedTheme } from '../lib/firebase/types';
import ThemeOverview from './YourStory/ThemeOverview';
import QuestionModal from './YourStory/QuestionModal';
import ThemeSummaryViewer from './shared/ThemeSummaryViewer';
import LockedThemeModal from './shared/LockedThemeModal';
import ZenLoader from './YearInReview/ZenLoader';
import { generateStructuredInfo } from '../lib/openai';

export default function DailyReflection() {
  const navigate = useNavigate();
  const { user, userData } = useAuth();
  const [experience, setExperience] = useState<Experience | null>(null);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedTheme, setSelectedTheme] = useState<Theme | null>(null);
  const [showThemeOverview, setShowThemeOverview] = useState(false);
  const [themeContent, setThemeContent] = useState<{ [key: string]: any }>({});
  const [currentDay, setCurrentDay] = useState(1);
  const [showThemeGrid, setShowThemeGrid] = useState(true);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [selectedThemeId, setSelectedThemeId] = useState<string | null>(null);
  const [showNewSummaryViewer, setShowNewSummaryViewer] = useState(false);
  const [expandedThemeId, setExpandedThemeId] = useState<string | null>(null);
  const [createdFor, setCreatedFor] = useState<string>('');
  const [currentContent, setCurrentContent] = useState('');
  const [showLockedModal, setShowLockedModal] = useState(false);
  const [recentCompletions, setRecentCompletions] = useState<CompletedTheme[]>([]);
  const [completedThemeIds, setCompletedThemeIds] = useState<Set<string>>(new Set());

  useEffect(() => {
    const loadExperience = async () => {
      try {
        setLoading(true);
        console.log('Loading daily reflection experience...');

        // Get the daily reflection experience
        const experiencesRef = collection(db, 'experiences');
        const experienceQuery = query(
          experiencesRef,
          where('route', '==', 'daily-reflection'),
          where('isActive', '==', true)
        );
        const experienceSnapshot = await getDocs(experienceQuery);
        
        if (!experienceSnapshot.empty) {
          const experienceDoc = experienceSnapshot.docs[0];
          const dailyExperience = {
            id: experienceDoc.id,
            ...experienceDoc.data()
          } as Experience;
          
          setExperience(dailyExperience);
          
          // Load themes
          const themesRef = collection(db, 'themes');
          const themesQuery = query(
            themesRef,
            where('experienceId', '==', dailyExperience.id),
            orderBy('order', 'asc')
          );
          const themesSnapshot = await getDocs(themesQuery);
          const themesData = themesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as Theme[];
          
          setThemes(themesData);

          // Load recent completions if user is logged in
          if (user) {
            const completionsRef = collection(db, 'completedThemes');
            const completionsQuery = query(
              completionsRef,
              where('userId', '==', user.uid),
              where('experienceId', '==', dailyExperience.id),
              orderBy('completedAt', 'desc'),
              limit(10)
            );
            const completionsSnapshot = await getDocs(completionsQuery);
            const completions = completionsSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            })) as CompletedTheme[];
            
            // Track completed theme IDs
            const completedIds = new Set(completions.map(c => c.themeId));
            setCompletedThemeIds(completedIds);
            
            setRecentCompletions(completions);
          }
        } else {
          console.error('Daily reflection experience not found');
          toast.error('This experience is not available yet');
          navigate('/');
        }
      } catch (error) {
        console.error('Error loading daily reflection:', error);
        toast.error('Unable to load experience');
      } finally {
        setLoading(false);
      }
    };

    loadExperience();
  }, [navigate, user]);

  const handleThemeSelect = (theme: Theme) => {
    setSelectedTheme(theme);
    setShowThemeOverview(true);
    setShowThemeGrid(false);
  };

  const handleStartTheme = () => {
    setShowThemeOverview(false);
    setCurrentDay(1);
    setCurrentContent('');
    setShowThemeGrid(false);
  };

  const handleSaveResponse = async () => {
    if (!currentContent.trim() || !selectedTheme) return;
    setIsGeneratingSummary(true);

    try {
      const themeId = selectedTheme.id;
      const updatedTheme = {
        ...themeContent,
        [themeId]: {
          ...themeContent[themeId],
          responses: {
            ...(themeContent[themeId]?.responses || {}),
            [currentDay]: currentContent
          }
        }
      };
      setThemeContent(updatedTheme);

      if (currentDay === selectedTheme.questions.length) {
        const responses = updatedTheme[themeId].responses;
        await handleGenerateSummary(responses);
        setSelectedThemeId(themeId);
        setShowNewSummaryViewer(true);
        setShowThemeGrid(true);
        setSelectedTheme(null);
      } else {
        setCurrentDay(prev => prev + 1);
        setCurrentContent('');
      }
    } catch (error) {
      console.error('Error saving response:', error);
      toast.error('Failed to save response');
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  const handleGenerateSummary = async (responses: { [key: number]: string }) => {
    if (!selectedTheme || !user || !userData) return;
    
    try {
      const content = Object.entries(responses)
        .sort(([a], [b]) => parseInt(a) - parseInt(b))
        .map(([day, content]) => {
          const question = selectedTheme.questions.find(q => q.id === parseInt(day));
          return `Question ${day}: ${question?.text}\nAnswer: ${content}`;
        })
        .join('\n\n');

      const systemPrompt = selectedTheme.systemPrompt || `Create an inspiring summary that highlights key themes, growth, and insights from the user's reflections. Structure the response in markdown format with these sections:

# Theme Journey
A concise narrative overview of the journey, challenges faced, and growth achieved.

## Highlights and Wins
Key accomplishments and positive moments (2-5 bullets)

## Strengths and Skills Used
Core competencies and abilities demonstrated throughout the journey (2-5 bullets)

## Impact of Actions
The effects of decisions and actions on both personal and professional life (2-5 bullets)

## Potential Future Story
A forward-looking paragraph that builds on the insights and growth shown.`;

      const response = await generateStructuredInfo(
        content,
        { model: 'gpt-4', temperature: 0.7 },
        systemPrompt + (createdFor ? `\n\nIMPORTANT: This summary is specifically created for ${createdFor}. Please personalize the insights and recommendations accordingly.` : '')
      );

      const timestamp = new Date().toISOString();
      const completedTheme = {
        userId: user.uid,
        userName: `${userData.firstName} ${userData.lastName}`,
        themeId: selectedTheme.id,
        questions: selectedTheme.questions,
        themeType: 'theme',
        experienceId: experience?.id,
        experienceName: experience?.title,
        responses: Object.entries(responses).map(([questionId, content]) => ({
          questionId: parseInt(questionId),
          question: selectedTheme.questions.find(q => q.id === parseInt(questionId))?.text || '',
          content,
          createdAt: timestamp
        })),
        summary: response.data,
        completedAt: timestamp
      };

      await addDoc(collection(db, 'completedThemes'), completedTheme);
      
      // Update local state
      setThemeContent(prev => ({
        ...prev,
        [selectedTheme.id]: {
          responses,
          summary: response.data,
          completedAt: timestamp
        }
      }));
      
      // Update completed theme IDs
      setCompletedThemeIds(prev => new Set([...prev, selectedTheme.id]));
      
      // Add to recent completions
      setRecentCompletions(prev => [completedTheme, ...prev]);
      
      toast.success('Theme completed and saved!');
    } catch (error) {
      console.error('Error generating summary:', error);
      toast.error('Failed to generate summary');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-indigo-200 border-t-indigo-600"></div>
          <p className="mt-4 text-indigo-600">Loading experience...</p>
        </div>
      </div>
    );
  }

  if (!experience) {
    return (
      <div className="min-h-screen bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="text-center">
            <h1 className="text-3xl font-bold text-indigo-900 mb-4">Experience Not Found</h1>
            <p className="text-indigo-600 mb-8">
              This experience is not available yet. Please check back later.
            </p>
            <button
              onClick={() => navigate('/')}
              className="px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
            >
              Return Home
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {showThemeGrid ? (
          <>
            <div className="mb-8">
              <h1 className="text-2xl font-bold text-indigo-900 mb-2">{experience.title}</h1>
              <p className="text-indigo-600">{experience.description}</p>
            </div>

            <div className="space-y-8">
              {themes.map((theme) => {
                const completedDays = Object.keys(themeContent[theme.id]?.responses || {}).length;
                const totalDays = theme.questions.length;
                const progress = (completedDays / totalDays) * 100;
                const isCompleted = completedThemeIds.has(theme.id);
                
                return (
                  <motion.div
                    key={theme.id}
                    whileHover={{ scale: 1.02 }}
                    className="bg-white p-8 rounded-2xl shadow-xl overflow-hidden cursor-pointer border-2 border-indigo-100 hover:shadow-2xl transition-all"
                    onClick={() => handleThemeSelect(theme)}
                  >
                    <div className="flex justify-between items-start mb-4">
                      <div>
                        <h3 className="text-xl font-semibold text-indigo-900">{theme.title}</h3>
                        <p className="text-indigo-600 mt-1">{theme.description}</p>
                        <div className="flex flex-wrap gap-3 mt-4">
                          <span className="px-3 py-1 bg-indigo-50 text-indigo-700 rounded-full text-sm">
                            {theme.questions.length} Questions
                          </span>
                        </div>
                      </div>
                    </div>

                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setExpandedThemeId(expandedThemeId === theme.id ? null : theme.id);
                      }}
                      className="mt-2 text-sm text-indigo-600 hover:text-indigo-700"
                    >
                      {expandedThemeId === theme.id ? 'Hide Preview' : 'Preview Questions'}
                    </button>

                    <motion.div
                      initial={false}
                      animate={{ height: expandedThemeId === theme.id ? 'auto' : 0 }}
                      className="overflow-hidden"
                    >
                      <div className="space-y-2 mb-4">
                        {theme.questions.map((question, index) => (
                          <div
                            key={question.id}
                            className="flex items-center space-x-2 text-sm text-gray-600 p-2 rounded-lg hover:bg-indigo-50"
                          >
                            <span className="w-5 h-5 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 font-medium">
                              {index + 1}
                            </span>
                            <span className="flex-1">{question.text}</span>
                          </div>
                        ))}
                      </div>
                    </motion.div>

                    <div className="mt-4 space-y-2">
                      <div className="flex justify-between items-center text-sm">
                        <span className="text-gray-500">
                          {isCompleted ? 'Completed' : `${completedDays}/${totalDays} completed`}
                        </span>
                        <div className="flex items-center space-x-4">
                          <button
                            onClick={() => handleThemeSelect(theme)}
                            className="text-indigo-600 font-medium hover:text-indigo-700"
                          >
                            Start Theme
                          </button>
                          {isCompleted && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedThemeId(theme.id);
                                setShowNewSummaryViewer(true);
                              }}
                              className="text-indigo-600 hover:text-indigo-700"
                            >
                              <FileText className="w-5 h-5" />
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                        <div
                          className={`h-full transition-all duration-300 ${
                            isCompleted ? 'bg-green-500' : 'bg-indigo-600'
                          }`}
                          style={{ width: `${progress}%` }}
                        />
                      </div>
                    </div>
                  </motion.div>
                );
              })}
            </div>
          </>
        ) : (
          <QuestionModal
            theme={selectedTheme}
            currentQuestion={currentDay}
            answers={themeContent[selectedTheme?.id]?.responses || {}}
            onClose={() => setShowThemeGrid(true)}
            onAnswer={(questionId, answer) => {
              if (!selectedTheme) return;
              setThemeContent(prev => ({
                ...prev,
                [selectedTheme.id]: {
                  ...prev[selectedTheme.id],
                  responses: {
                    ...(prev[selectedTheme.id]?.responses || {}),
                    [questionId]: answer
                  }
                }
              }));
              setCurrentContent(answer);
            }}
            onNext={() => setCurrentDay(prev => prev + 1)}
            onPrevious={() => setCurrentDay(prev => prev - 1)}
            onComplete={handleSaveResponse}
            isGeneratingSummary={isGeneratingSummary}
          />
        )}
      </div>

      {selectedTheme && (
        <ThemeOverview
          isOpen={showThemeOverview}
          onClose={() => setShowThemeOverview(false)}
          title={selectedTheme.title}
          description={selectedTheme.description}
          introduction={experience.customPrompt || ''}
          showCreatedFor={selectedTheme.showCreatedFor}
          onCreatedForChange={setCreatedFor}
          createdFor={createdFor}
          onStart={handleStartTheme}
        />
      )}

      <LockedThemeModal
        isOpen={showLockedModal}
        onClose={() => setShowLockedModal(false)}
      />

      {isGeneratingSummary && <ZenLoader />}

      {selectedThemeId && user && (
        <ThemeSummaryViewer
          isOpen={showNewSummaryViewer}
          onClose={() => {
            setShowNewSummaryViewer(false);
            setSelectedThemeId(null);
          }}
          themeId={selectedThemeId}
          userId={user.uid}
        />
      )}
    </div>
  );
}