import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { auth } from '../../lib/firebase';
import { toast } from 'react-hot-toast';
import { Users, UserPlus, UserMinus } from 'lucide-react';
import AddGroupMemberModal from './AddGroupMemberModal';
import GroupSelector from './GroupSelector';

export interface Group {
  id: string;
  group_name: string;
  group_code: string;
  total_seats: number;
  seats_used: number;
  seats_remaining: number;
  members: string[];
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export default function GroupAdminDashboard() {
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState<Group[]>([]);
  const [members, setMembers] = useState<User[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);

  useEffect(() => {
    loadGroups();
  }, []);
  
  const loadGroups = async () => {
    try {
      setLoading(true);
      
      const groupsToLoad = new Set();
      
      // Get current user's document to check group_admin_for array
      const userDoc = await getDoc(doc(db, 'users', auth.currentUser?.uid || ''));
      const userData = userDoc.data();
      
      // Load groups from group_admin_for array
      if (userData?.group_admin_for?.length > 0) {
        const groupsRef = collection(db, 'groups');
        const q = query(groupsRef, where('__name__', 'in', userData.group_admin_for));
        const snapshot = await getDocs(q);
        snapshot.docs.forEach(doc => groupsToLoad.add({
          id: doc.id,
          ...doc.data()
        }));
      }
      
      // Load groups where user is direct admin
      if (userData?.group_admin === true) {
        const groupRef = collection(db, 'groups');
        const snapshot = await getDocs(query(groupRef, where('groupAdmin', '==', auth.currentUser?.uid)));
        snapshot.docs.forEach(doc => groupsToLoad.add({
          id: doc.id,
          ...doc.data()
        }));
      }

      const uniqueGroups = Array.from(groupsToLoad);
      
      if (uniqueGroups.length === 0) {
        console.log('User has no admin groups');
        setGroups([]);
        setLoading(false);
        return;
      }
      
      console.log('Loaded admin groups:', uniqueGroups);
      setGroups(uniqueGroups);
      
      if (uniqueGroups.length > 0) {
        setSelectedGroup(uniqueGroups[0]);
        await loadMembers(uniqueGroups[0]);
      }
    } catch (error) {
      console.error('Error loading groups:', error);
      toast.error('Failed to load groups');
    } finally {
      setLoading(false);
    }
  };

  const loadMembers = async (group: Group) => {
    try {
      const members = await Promise.all(
        group.members.map(async (userId) => {
          const userDoc = await getDoc(doc(db, 'users', userId));
          return {
            id: userDoc.id,
            ...userDoc.data()
          } as User;
        })
      );
      setMembers(members);
    } catch (error) {
      console.error('Error loading members:', error);
      toast.error('Failed to load group members');
    }
  };

  const handleRemoveMember = async (userId: string) => {
    if (!selectedGroup || !window.confirm('Are you sure you want to remove this member?')) {
      return;
    }

    try {
      const groupRef = doc(db, 'groups', selectedGroup.id);
      await updateDoc(groupRef, {
        members: selectedGroup.members.filter(id => id !== userId),
        seats_used: selectedGroup.seats_used - 1,
        seats_remaining: selectedGroup.seats_remaining + 1
      });

      // Update user's group access
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        group: null,
        access_level: 'free'
      });

      toast.success('Member removed successfully');
      await loadGroups();
    } catch (error) {
      console.error('Error removing member:', error);
      toast.error('Failed to remove member');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-sm rounded-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-xl font-semibold text-gray-900">My Groups</h2>
          <div className="mt-4">
            <GroupSelector
              groups={groups}
              selectedGroup={selectedGroup}
              onGroupSelect={async (group) => {
                setSelectedGroup(group);
                await loadMembers(group);
              }}
            />
          </div>
        </div>
        <button
          onClick={() => setShowAddMemberModal(true)}
          disabled={!selectedGroup?.seats_remaining}
          className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          <UserPlus className="w-4 h-4" />
          <span>Add Member</span>
        </button>
      </div>

      {selectedGroup && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <div className="bg-indigo-50 p-4 rounded-lg">
              <div className="flex items-center space-x-2 text-indigo-600 mb-2">
                <Users className="w-5 h-5" />
                <span className="font-medium">Total Seats</span>
              </div>
              <p className="text-2xl font-bold text-indigo-900">{selectedGroup.total_seats}</p>
            </div>
            
            <div className="bg-green-50 p-4 rounded-lg">
              <div className="flex items-center space-x-2 text-green-600 mb-2">
                <UserPlus className="w-5 h-5" />
                <span className="font-medium">Available Seats</span>
              </div>
              <p className="text-2xl font-bold text-green-900">{selectedGroup.seats_remaining}</p>
            </div>

            <div className="bg-purple-50 p-4 rounded-lg">
              <div className="flex items-center space-x-2 text-purple-600 mb-2">
                <span className="font-medium">Group Code</span>
              </div>
              <p className="text-2xl font-bold text-purple-900">{selectedGroup.group_code}</p>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Member Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {members.map((member) => (
                  <tr key={member.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {member.firstName} {member.lastName}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{member.email}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right">
                      <button
                        onClick={() => handleRemoveMember(member.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        {auth.currentUser?.uid !== member.id && <UserMinus className="w-5 h-5" />}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {showAddMemberModal && selectedGroup && (
        <AddGroupMemberModal
          isOpen={showAddMemberModal}
          onClose={() => setShowAddMemberModal(false)}
          group={selectedGroup}
          onMemberAdded={loadGroups}
        />
      )}
    </div>
  );
}