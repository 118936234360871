import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, collection, query, where, getDocs, updateDoc, arrayUnion } from 'firebase/firestore';
import { auth, db } from '../../lib/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import { AccessLevel } from '../../enums/AccessLevel';

interface PurchaseAuthProps {
  showGroupFields?: boolean;
  onQuantityChange?: (quantity: number) => void;
}

export default function PurchaseAuth({ showGroupFields = false, onQuantityChange }: PurchaseAuthProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [validGroup, setvalidGroup] = useState(false);
  const [groupData, setGroupData] = useState<any>({});
  const [groupDocID, setGroupDocID] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { user } = useAuth();
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  // Check if user is already logged in when component mounts
  useEffect(() => {
    if (user) {
      setUserLoggedIn(true);
    }
  }, []); 

  const getGroupData = async () => {
    let validGroup: boolean = false;
    if (!groupCode.trim()) return;

    const groupsRef = collection(db, 'groups');
    const q = query(groupsRef, where('group_code', '==', groupCode.trim()));
    const snapshot = await getDocs(q);
    
    if (!snapshot.empty) {
      let groupData = snapshot.docs[0].data();
      setGroupData(groupData);
      setGroupDocID(snapshot.docs[0].id);
      
      validGroup = true;
      if (groupData.seats_remaining <= 0) {
        validGroup = false;
      }
      
      const currentDate = new Date();
      const startDate = new Date(groupData.start_date);
      const endDate = new Date(groupData.end_date);
      
      if (currentDate < startDate || currentDate > endDate) {
        validGroup = false;
      } 
    }
    setvalidGroup(validGroup);
  }

  const handleUserSignup = async () => {
    try {
      // Basic input validation
      if (!email || !password || !firstName || !lastName) {
        setError('All fields are required.');
        setLoading(false);
        return;
      }

      const timestamp = new Date().toISOString();
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = await userCredential.user.uid;
      const endDate = new Date(groupData.end_date);

      // Create user document
      await setDoc(doc(db, 'users', userId), {
        firstName,
        lastName,
        email: email.toLowerCase(),
        group: groupCode.trim() || null,
        access_level: AccessLevel.Free,
        createdAt: timestamp,
        membership_start: validGroup ? timestamp : null,
        membership_end: validGroup ? endDate : null
      });


      // Update group if joining one
      if (validGroup) {
        const groupRef = doc(db, 'groups', groupDocID);
        await updateDoc(groupRef, {
          members: arrayUnion(userId),
          seats_used: (groupData.seats_used || 0) + 1,
          seats_remaining: Math.max(0, (groupData.seats_remaining || 0) - 1)
        });
      }

      toast.success('Account created successfully!');

    } catch (err: any) {
      console.error('Error signing up:', err);
      const errorMessage = 
        err.code === 'auth/email-already-in-use' ? 'An account already exists with this email' :
        err.message || 'Failed to create account. Please try again.';
      setError(errorMessage);
      toast.error(errorMessage);
    } 
    finally {
      setLoading(false);
    }
  }

  const handlePayment = async () => {

        try {
          const userId = auth.currentUser?.uid;
          const response = await fetch('/api/create-checkout-session', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: userId, groupCode: groupCode.trim(), quantity: quantity }),
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
      
          const data = await response.json();
          console.log('Success:', data);
          window.location.href = data.url;
      
        } catch (err: any) {
          console.error('Error:', err.message);
          const errorMessage = 'There was a problem with the payment. Please try again.';
          setError(errorMessage);
          toast.error(errorMessage);
        } finally {
          setLoading(false);
        }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    await getGroupData();
    if (!userLoggedIn) {
      await handleUserSignup();
    }
    if (!validGroup) {
      await handlePayment();
    }
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(e.target.value) || 1;
    setQuantity(newQuantity);
    onQuantityChange?.(newQuantity);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      
      {!userLoggedIn && (
        <>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <input
                id="firstName"
                type="text"
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <input
                id="lastName"
                type="text"
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              id="email"
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              id="password"
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </>
      )}

      {userLoggedIn && (

        <p className="text-xl text-indigo-700 max-w-2xl mx-auto lg:mx-0">
          Activate your full membership to unlock your path to personal growth
        </p>
      )}
      
      {!showGroupFields && (
      <div>
        <label htmlFor="groupCode" className="block text-sm font-medium text-gray-700">
          Group Code (Optional)
        </label>
        <input
          id="groupCode"
          type="text"
          value={groupCode}
          onChange={(e) => setGroupCode(e.target.value)}
          placeholder="Enter your group code if you have one"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        />
        <p className="mt-1 text-sm text-gray-500">
          If you have a group code from your organization, enter it here
        </p>
      </div>
      )}
      {showGroupFields && (
        <div>
        <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">
          Quantity
        </label>
        <input
          id="quantity"
          type="number"
          min="2"
          value={quantity}
          onChange={handleQuantityChange}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        />
        <p className="mt-1 text-sm text-gray-500">
          If you have a group code from your organization, enter it here
        </p>
      </div>
      )}

      {error && (
        <div className="text-red-600 text-sm text-center">{error}</div>
      )}

      <button
        type="submit"
        disabled={loading}
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
      >
        {loading ? 'Creating Account...' : 'Continue to Payment'}
      </button>
    </form>
  );
}