import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, doc, updateDoc, addDoc, getDoc } from 'firebase/firestore';
import { db, isUserAdmin, syncGroupMembers } from '../../lib/firebase';
import { Edit2, Save, X, Users, UserPlus, UsersRound } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { useAuth } from '../../contexts/AuthContext';
import AddGroupModal from './AddGroupModal';
import GroupMembersModal from './GroupMembersModal';

interface Group {
  id: string;
  group_name: string;
  group_code: string;
  total_seats: number;
  members?: string[];
  start_date: string;
  end_date: string;
}

export default function Groups() {
  const { user } = useAuth();
  const [groups, setGroups] = useState<Group[]>([]);
  const [loading, setLoading] = useState(true);
  const [editingGroup, setEditingGroup] = useState<string | null>(null);
  const [editForm, setEditForm] = useState<Partial<Group>>({});
  const [error, setError] = useState<string | null>(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [showMembersModal, setShowMembersModal] = useState(false);

  useEffect(() => {
    if (user?.uid) {
      fetchGroups();
    }
  }, [user?.uid]);

  const fetchGroups = async () => {
    try {
      if (!user) {
        console.log('No authenticated user');
        throw new Error('No authenticated user');
      }

      // Verify admin status
      const isAdmin = await isUserAdmin(user.uid);
      console.log('Admin status check:', { userId: user.uid, isAdmin });
      
      if (!isAdmin) {
        console.log('User not admin:', user.uid);
        setError('You do not have administrator privileges to access this page');
        setLoading(false);
        return;
      }

      const q = query(collection(db, 'groups'), orderBy('group_name'));
      const querySnapshot = await getDocs(q);
      console.log('Groups query result:', querySnapshot.docs.length);
      
      const groupsData = await Promise.all(querySnapshot.docs.map(async doc => {
        // Sync members for each group
        await syncGroupMembers(doc.id);
        return {
        id: doc.id,
        ...doc.data()
        };
      })) as Group[];
      
      console.log('Fetched groups:', groupsData);
      setGroups(groupsData);
    } catch (error) {
      console.error('Error fetching groups:', error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to load groups';
      console.error('Detailed error:', error);
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const [showEditModal, setShowEditModal] = useState(false);

  const handleEdit = (group: Group) => {
    setSelectedGroup(group);
    setShowEditModal(true);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4">
        <h3 className="text-lg font-medium text-red-800 mb-2">Error Loading Groups</h3>
        <p className="text-red-700">{error}</p>
        <button
          onClick={() => {
            setError(null);
            fetchGroups();
          }}
          className="mt-4 px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-sm rounded-lg overflow-hidden">
      <div className="p-6 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-900">Groups</h2>
          <button
            onClick={() => setShowAddModal(true)}
            className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
          >
            <UserPlus className="w-4 h-4" />
            <span>Add Group</span>
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Group Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Code
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Seats
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Period
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Members
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {groups.map((group) => (
              <tr key={group.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  {editingGroup === group.id ? (
                    <input
                      type="text"
                      value={editForm.group_name || ''}
                      onChange={(e) => setEditForm({ ...editForm, group_name: e.target.value })}
                      className="w-full px-2 py-1 border rounded"
                    />
                  ) : (
                    <div className="text-sm font-medium text-gray-900">
                      {group.group_name}
                    </div>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {editingGroup === group.id ? (
                    <input
                      type="text"
                      value={editForm.group_code || ''}
                      onChange={(e) => setEditForm({ ...editForm, group_code: e.target.value })}
                      className="w-full px-2 py-1 border rounded"
                    />
                  ) : (
                    <div className="text-sm text-gray-500">
                      {group.group_code}
                    </div>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {editingGroup === group.id ? (
                    <input
                      type="number"
                      value={editForm.total_seats || 0}
                      onChange={(e) => setEditForm({ 
                        ...editForm, 
                        total_seats: parseInt(e.target.value),
                        seats_remaining: parseInt(e.target.value) - (group.seats_used || 0)
                      })}
                      className="w-20 px-2 py-1 border rounded"
                    />
                  ) : (
                    <div className="text-sm text-gray-500">
                      {(group.members?.length || 0)} / {group.total_seats}
                      <div className="text-xs text-gray-400">
                        {group.total_seats - (group.members?.length || 0)} available
                      </div>
                    </div>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-500">
                    <div>{new Date(group.start_date).toLocaleDateString()}</div>
                    <div>{new Date(group.end_date).toLocaleDateString()}</div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    onClick={() => {
                      setSelectedGroup(group);
                      setShowMembersModal(true);
                    }}
                    className="flex items-center space-x-2 text-indigo-600 hover:text-indigo-900"
                  >
                    <UsersRound className="w-4 h-4" />
                    <span>{group.members?.length || 0} members</span>
                  </button>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {editingGroup === group.id ? (
                    <div className="flex space-x-2">
                      <button
                        onClick={handleSave}
                        className="p-1 text-green-600 hover:text-green-900"
                      >
                        <Save className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => setEditingGroup(null)}
                        className="p-1 text-red-600 hover:text-red-900"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={() => handleEdit(group)}
                      className="p-1 text-blue-600 hover:text-blue-900"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      <AddGroupModal
        isOpen={showAddModal}
        onClose={() => setShowAddModal(false)}
        onGroupAdded={fetchGroups}
      />
      
      {selectedGroup && (
        <>
          <GroupMembersModal
            isOpen={showMembersModal}
            onClose={() => {
              setShowMembersModal(false);
              setSelectedGroup(null);
            }}
            group={selectedGroup}
            onMembersUpdated={fetchGroups}
          />
          <EditGroupModal
            isOpen={showEditModal}
            onClose={() => {
              setShowEditModal(false);
              setSelectedGroup(null);
            }}
            group={selectedGroup}
            onGroupUpdated={fetchGroups}
          />
        </>
      )}
    </div>
  );
}

import EditGroupModal from './EditGroupModal';