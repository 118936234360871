import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, deleteUser } from 'firebase/auth';
import { 
  getFirestore, 
  doc, 
  getDoc, 
  collection, 
  query, 
  where, 
  orderBy, 
  addDoc, 
  updateDoc, 
  getDocs, 
  setDoc,
  runTransaction,
  deleteDoc
} from 'firebase/firestore';
import { toast } from 'react-hot-toast';

// Development fallback configuration
const devConfig = {
  apiKey: "AIzaSyBn7FLGrr9vENU7uHetOa02am__CvHL5K0",
  authDomain: "findinggood-dfcaf.firebaseapp.com",
  projectId: "findinggood-dfcaf",
  storageBucket: "findinggood-dfcaf.firebasestorage.app",
  messagingSenderId: "335726476719",
  appId: "1:335726476719:web:f6894f8ec7518daa2ac35a",
  measurementId: "G-E50HB9N2TK"
};

// Validate required environment variables
const requiredEnvVars = [
  'VITE_FIREBASE_API_KEY',
  'VITE_FIREBASE_AUTH_DOMAIN',
  'VITE_FIREBASE_PROJECT_ID',
  'VITE_FIREBASE_STORAGE_BUCKET',
  'VITE_FIREBASE_MESSAGING_SENDER_ID',
  'VITE_FIREBASE_APP_ID'
] as const;

// Use environment variables if available, otherwise fall back to dev config
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY || devConfig.apiKey,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN || devConfig.authDomain,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID || devConfig.projectId,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET || devConfig.storageBucket,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID || devConfig.messagingSenderId,
  appId: import.meta.env.VITE_FIREBASE_APP_ID || devConfig.appId,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID || devConfig.measurementId
};

// Initialize Firebase with validation
let app;
let auth;
let db;

try {
  app = initializeApp(firebaseConfig);
  auth = getAuth(app);
  db = getFirestore(app);
} catch (error) {
  console.error('Error initializing Firebase:', error);
  toast.error('Failed to initialize Firebase. Please check configuration.');
  throw error;
}

// Auth helper functions
export const signIn = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.error('Error signing in:', error);
    toast.error('Authentication failed. Please try again.');
    throw error;
  }
};
// Check if user is admin
export const isUserAdmin = async (userId: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) return false;
    const userData = userDoc.data();
    const isAdmin = userData && (
      userData.access_level === 'admin' || 
      userData.isAdmin === true || 
      userData.role === 'admin'
    );
    console.log('Admin check:', {
      userId,
      exists: userDoc.exists(),
      userData,
      isAdmin
    });
    return isAdmin;
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};

// Experience and Theme interfaces
export interface Experience {
  id: string;
  title: string;
  description: string;
  route: string;
  isActive: boolean;
  order: number;
}

export interface Theme {
  id: string;
  experienceId: string;
  title: string;
  description: string;
  order: number;
  questions: Array<{
    id: number;
    text: string;
  }>;
}

export interface CompletedTheme {
  userId: string;
  userName: string;
  themeId: string;
  themeType?: 'theme' | 'experience_summary';
  experienceId: string;
  experienceName?: string;
  responses: Array<{
    questionId: number;
    content: string;
    createdAt: string;
  }>;
  summary: string;
  completedAt: string;
}

export const saveCompletedTheme = async (theme: CompletedTheme) => {
  try {
    // Validate required fields
    const requiredFields = ['userId', 'themeId', 'experienceId', 'responses', 'summary'];
    const missingFields = requiredFields.filter(field => !theme[field]);
    
    if (missingFields.length > 0) {
      const error = `Missing required fields: ${missingFields.join(', ')}`;
      console.error(error);
      toast.error('Error saving theme: Missing required data');
      throw new Error(error);
    }

    // Get theme info from mock data
    const themeInfo = mockThemes.find(t => t.id === theme.themeId);
    if (theme.themeType !== 'experience_summary') {
      // For regular themes, use the theme title
      theme.themeName = themeInfo?.title || theme.themeId;
    } else {
      // For experience summaries, use a special name
      theme.themeName = 'Year In Review Summary';
    }

    // Ensure responses array is properly formatted
    const validResponses = theme.responses.every(r => 
      typeof r.questionId === 'number' && 
      typeof r.content === 'string' &&
      typeof r.createdAt === 'string'
    );

    if (!validResponses) {
      const error = 'Invalid response format';
      console.error(error);
      toast.error('Error saving theme: Invalid response format');
      throw new Error(error);
    }

    const docRef = await addDoc(collection(db, 'completedThemes'), theme);
    return docRef.id;
  } catch (error) {
    console.error('Error saving completed theme:', error);
    toast.error('Failed to save theme. Please try again.');
    throw error;
  }
};

// Theme progress tracking
export interface ThemeProgress {
  id?: string;
  userId: string;
  userName: string;
  experienceId: string;
  experienceName: string;
  themeId: string;
  themeName: string;
  startedAt: string;
  completedAt?: string;
  responses: Array<{
    questionId: number;
    content: string;
    createdAt: string;
  }>;
  summary?: string;
  isCompleted: boolean;
}

export const startTheme = async (progress: Omit<ThemeProgress, 'id'>) => {
  try {
    const docRef = await addDoc(collection(db, 'themeProgress'), progress);
    
    // Log the action
    await addDoc(collection(db, 'userActions'), {
      userId: progress.userId,
      userName: progress.userName,
      actionType: 'theme_start',
      themeId: progress.themeId,
      themeName: progress.themeName,
      timestamp: progress.startedAt
    });
    
    return docRef.id;
  } catch (error) {
    console.error('Error starting theme:', error);
    throw error;
  }
};

export const saveThemeResponse = async (progressId: string, response: { questionId: number; content: string }) => {
  try {
    const progressRef = doc(db, 'themeProgress', progressId);
    const timestamp = new Date().toISOString();
    
    await updateDoc(progressRef, {
      [`responses.${response.questionId}`]: {
        ...response,
        createdAt: timestamp
      }
    });
  } catch (error) {
    console.error('Error saving response:', error);
    throw error;
  }
};

export const completeTheme = async (progressId: string, summary: string) => {
  try {
    const progressRef = doc(db, 'themeProgress', progressId);
    const timestamp = new Date().toISOString();
    const progress = await getDoc(progressRef);
    const progressData = progress.data() as ThemeProgress;
    
    const updates = {
      summary,
      completedAt: timestamp,
      isCompleted: true
    };
    
    await updateDoc(progressRef, updates);
    
    // Log the action
    await addDoc(collection(db, 'userActions'), {
      userId: progressData.userId,
      userName: progressData.userName,
      actionType: 'theme_complete',
      themeId: progressData.themeId,
      themeName: progressData.themeName,
      timestamp,
      summary
    });
    
  } catch (error) {
    console.error('Error completing theme:', error);
    throw error;
  }
};

export const getThemeProgress = async (userId: string, themeId: string) => {
  try {
    const q = query(
      collection(db, 'themeProgress'),
      where('userId', '==', userId),
      where('themeId', '==', themeId),
      orderBy('startedAt', 'desc')
    );
    
    const snapshot = await getDocs(q);
    if (snapshot.empty) return null;
    
    const doc = snapshot.docs[0];
    return { id: doc.id, ...doc.data() } as ThemeProgress;
  } catch (error) {
    console.error('Error getting theme progress:', error);
    throw error;
  }
};

export const getCompletedThemes = async (userId: string): Promise<CompletedTheme[]> => {
  try {
    if (!userId) {
      throw new Error('User ID is required');
    }

    const q = query(
      collection(db, 'completedThemes'),
      where('userId', '==', userId),
      orderBy('completedAt', 'desc')
    );
    
    const snapshot = await getDocs(q);
    const themes = snapshot.docs
      .filter(doc => doc.exists())
      .map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as CompletedTheme[];

    return themes;
  } catch (error) {
    console.error('Error getting completed themes:', error);
    throw error;
  }
};

export interface CompletedExperience {
  id: string;
  userId: string;
  userName: string;
  experienceName: string;
  completedAt: string;
  summary: string;
  themes: Array<{
    themeId: string;
    themeName: string;
    summary: string;
  }>;
}

export const saveCompletedExperience = async (experience: CompletedExperience) => {
  try {
    const docRef = await addDoc(collection(db, 'completedExperiences'), experience);
    return docRef.id;
  } catch (error) {
    console.error('Error saving completed experience:', error);
    throw error;
  }
};

export const getCompletedExperiencesByUser = async (userId: string): Promise<CompletedExperience[]> => {
  try {
    const q = query(
      collection(db, 'completedExperiences'),
      where('userId', '==', userId),
      orderBy('completedAt', 'desc')
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as CompletedExperience[];
  } catch (error) {
    console.error('Error getting completed experiences:', error);
    return [];
  }
};

export const getCompletedExperiences = async (userId: string): Promise<CompletedExperience[]> => {
  try {
    const q = query(
      collection(db, 'completedThemes'),
      where('userId', '==', userId),
      orderBy('completedAt', 'desc')
    );
    
    const snapshot = await getDocs(q);
    const themes = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as CompletedTheme[];
    
    console.log('Retrieved themes:', themes);
    return themes;
  } catch (error) {
    console.error('Error getting completed experiences:', error);
    return [];
  }
};

// Mock data for development
const mockExperience = {
  id: 'EvBidxFPOpRaBEki3Dee',
  title: 'Year In Review',
  description: 'Reflect on your journey through the past year',
  route: 'year-in-review',
  isActive: true,
  order: 1
};

const mockThemes = [
  {
    id: 'theme1',
    experienceId: 'EvBidxFPOpRaBEki3Dee',
    title: 'Accomplishments and Pride',
    description: 'Celebrate your achievements and moments of pride from the past year',
    order: 1,
    questions: [
      { text: 'What was your biggest accomplishment this year?', id: 1 },
      { text: 'What action are you most proud of taking this year?', id: 2 },
      { text: 'What day did you feel most proud of yourself this year?', id: 3 },
      { text: 'What positive impact did you have on others?', id: 4 },
      { text: 'When were you happiest this year?', id: 5 }
    ]
  },
  {
    id: 'theme2',
    experienceId: 'EvBidxFPOpRaBEki3Dee',
    title: 'Learning and Growth',
    description: 'Reflect on how you have developed and what you have learned',
    order: 2,
    questions: [
      { text: 'What new skills did you develop this year?', id: 1 },
      { text: 'What challenges helped you grow?', id: 2 },
      { text: 'What important lessons did you learn?', id: 3 },
      { text: 'How did you push outside your comfort zone?', id: 4 },
      { text: 'What surprised you about yourself?', id: 5 }
    ]
  },
  {
    id: 'theme3',
    experienceId: 'EvBidxFPOpRaBEki3Dee',
    title: 'Relationships and Connections',
    description: 'Explore how your relationships evolved and grew stronger',
    order: 3,
    questions: [
      { text: 'Who had the biggest positive impact on you this year?', id: 1 },
      { text: 'What relationship grew stronger?', id: 2 },
      { text: 'How did you support others?', id: 3 },
      { text: 'What new connections did you make?', id: 4 },
      { text: 'What relationship taught you the most?', id: 5 }
    ]
  },
  {
    id: 'theme4',
    experienceId: 'EvBidxFPOpRaBEki3Dee',
    title: 'Future Vision',
    description: 'Look ahead and plan your path forward',
    order: 4,
    questions: [
      { text: 'What excites you most about the future?', id: 1 },
      { text: 'What strengths will you build upon?', id: 2 },
      { text: 'What new opportunities do you see?', id: 3 },
      { text: 'How will you challenge yourself?', id: 4 },
      { text: 'What impact do you want to have?', id: 5 }
    ]
  }
];

// Simplified mock functions for demo mode
export const initializeAdminUsers = async () => {
  try {
    // Initialize test group
    const groupsRef = collection(db, 'groups');
    const q = query(groupsRef, where('group_code', '==', 'blueberrytesters'));
    const snapshot = await getDocs(q);
    
    if (snapshot.empty) {
      const weekAgo = new Date();
      weekAgo.setDate(weekAgo.getDate() - 7);
      
      const endDate = new Date('2025-12-31');
      
      await addDoc(groupsRef, {
        group_name: 'Blueberry Test Users',
        group_code: 'blueberrytesters',
        total_seats: 50,
        members: [],
        start_date: weekAgo.toISOString(),
        end_date: endDate.toISOString(),
        created_at: new Date().toISOString()
      });
      
      console.log('Test group initialized');
    }
  } catch (error) {
    console.error('Error initializing test group:', error);
  }
};

// Function to sync group members with user data
export const syncGroupMembers = async (groupId: string) => {
  try {
    // Get group data
    const groupRef = doc(db, 'groups', groupId);
    const groupDoc = await getDoc(groupRef);
    const groupData = groupDoc.data();
    
    if (!groupData) {
      throw new Error('Group not found');
    }
    
    // Get all users with this group code
    const usersRef = collection(db, 'users');
    const usersQuery = query(
      usersRef, 
      where('group', 'in', [groupId, groupData.group_code, groupData.group_name])
    );
    const userSnapshot = await getDocs(usersQuery);
    
    const memberIds = userSnapshot.docs.map(doc => doc.id);
    
    // Update group document with current member list
    await updateDoc(groupRef, {
      members: memberIds,
      seats_used: memberIds.length,
      seats_remaining: Math.max(0, groupData.total_seats - memberIds.length)
    });
    
    console.log('Group members synchronized successfully');
  } catch (error) {
    console.error('Error syncing group members:', error);
    throw error;
  }
};

export const getExperiences = async () => {
  return Promise.resolve([mockExperience]);
};

export const getThemesByExperience = async (experienceId: string) => {
  return Promise.resolve(mockThemes.filter(theme => theme.experienceId === experienceId));
};

// Delete user completely (both Auth and Firestore)
export const deleteUserCompletely = async (userId: string) => {
  try {
    // Get user's group info first
    const userDoc = await getDoc(doc(db, 'users', userId));
    const userData = userDoc.data();
    const userGroup = userData?.group;

    // If user is in a group, update group members
    if (userGroup) {
      const groupsRef = collection(db, 'groups');
      const q = query(groupsRef, where('id', '==', userGroup));
      const snapshot = await getDocs(q);
      
      if (!snapshot.empty) {
        const groupDoc = snapshot.docs[0];
        const groupData = groupDoc.data();
        
        await updateDoc(doc(db, 'groups', groupDoc.id), {
          members: (groupData.members || []).filter(id => id !== userId),
          seats_used: Math.max(0, (groupData.seats_used || 0) - 1),
          seats_remaining: (groupData.seats_remaining || 0) + 1
        });
      }
    }

    // Delete Firestore user document
    await deleteDoc(doc(db, 'users', userId));

    // Delete from Firebase Auth if possible
    try {
      const userRecord = await auth.getUser(userId);
      await auth.deleteUser(userId);
    } catch (authError) {
      console.log('User already deleted from Auth or not found:', authError);
      // Continue with the process even if Auth deletion fails
    }

    return true;
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

export { auth, db };