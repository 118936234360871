import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Header from './components/shared/Header';
import { AuthProvider } from './contexts/AuthContext';
import { ReflectionsProvider } from './contexts/ReflectionsContext';
import { RequireAuth } from './features/auth/components/RequireAuth';
import Footer from './components/shared/Footer';
import TermsOfService from './components/legal/TermsOfService';
import { RequirePaidAccess } from './features/auth/components/RequirePaidAccess';
import { RequireAdmin } from './features/auth/components/RequireAdmin';
import TestHome from './components/TestHome';
import Landing from './components/Landing';
import PublicShare from './components/shared/PublicShare';
import DeeperReflections from './components/DeeperReflections';
import DailyReflection from './components/DailyReflection';
import Purchase from './components/Purchase';
import YearInReview from './components/YearInReview/YearInReview';
import SharedExperiences from './components/SharedExperiences/SharedExperiences';
import SuccessMap from './components/SuccessMap';
import AIAnalyzer from './components/AIAnalyzer';
import AdminLayout from './components/Admin/AdminLayout';
import AdminDashboard from './components/Admin/AdminDashboard';
import AdminLogin from './components/Admin/AdminLogin';
import AdminApps from './components/Admin/AdminApps';
import DeepDives from './components/Admin/DeepDives';
import AdminExperiences from './components/Admin/AdminExperiences';
import CompletedThemes from './components/Admin/CompletedThemes';
import UserActivity from './components/Admin/UserActivity';
import UsersTable from './components/Admin/UsersTable';
import Groups from './components/Admin/Groups';
import Dashboard from './components/Dashboard/Dashboard';
import UserLogin from './components/UserAuth/UserLogin';
import NotFound from './components/NotFound';
import GroupAdminDashboard from './components/Admin/GroupAdminDashboard';
import TestingPage from './components/Admin/Testing/TestingPage'; //Import the TestingPage component
import GroupManagementDashboard from './components/GroupManagement/GroupManagementDashboard';
import { inject } from '@vercel/analytics';


export default function App() {

  inject();
  return (
    <AuthProvider>
      <Router>
        <ReflectionsProvider>
          <Toaster position="top-center" />
          <div className="min-h-screen bg-gray-50 flex flex-col">
            <Header />
            <Routes>
              <Route path="/" element={
                  <Landing />
              } />
              <Route path="/test-home" element={
                <RequireAuth>
                  <TestHome />
                </RequireAuth>
              } />
              <Route path="/deeper-reflections" element={
                <RequireAuth>
                  <RequirePaidAccess>
                    <DeeperReflections />
                  </RequirePaidAccess>
                </RequireAuth>
              } />
              <Route path="/purchase" element={<Purchase />} />
              <Route path="/login" element={<UserLogin />} />
              <Route path="/share/:shareId" element={<PublicShare />} />

              {/* Protected Routes */}
              <Route path="/:experienceRoute" element={
                <RequireAuth>
                  <RequirePaidAccess>
                    <YearInReview />
                  </RequirePaidAccess>
                </RequireAuth>
              } />
              <Route path="/year-in-review" element={
                <RequireAuth>
                  <RequirePaidAccess>
                    <YearInReview />
                  </RequirePaidAccess>
                </RequireAuth>
              } />
              <Route path="/daily-reflection" element={
                <RequireAuth>
                  <RequirePaidAccess>
                    <DailyReflection />
                  </RequirePaidAccess>
                </RequireAuth>
              } />
              <Route path="/shared-experiences" element={
                <RequireAuth>
                  <RequirePaidAccess>
                    <SharedExperiences />
                  </RequirePaidAccess>
                </RequireAuth>
              } />
              <Route path="/success-map" element={
                <RequireAuth>
                  <RequirePaidAccess>
                    <SuccessMap />
                  </RequirePaidAccess>
                </RequireAuth>
              } />
              <Route path="/analyzer" element={
                <RequireAuth>
                  <RequirePaidAccess>
                    <AIAnalyzer />
                  </RequirePaidAccess>
                </RequireAuth>
              } />

              {/* Admin Routes */}
              <Route path="/admin" element={<RequireAuth><RequireAdmin><AdminLayout><AdminDashboard /></AdminLayout></RequireAdmin></RequireAuth>} />
              <Route path="/admin/users" element={<RequireAuth><RequireAdmin><AdminLayout><UsersTable /></AdminLayout></RequireAdmin></RequireAuth>} />
              <Route path="/admin/groups" element={<RequireAuth><RequireAdmin><AdminLayout><Groups /></AdminLayout></RequireAdmin></RequireAuth>} />
              <Route path="/admin/deep-dives" element={<RequireAuth><RequireAdmin><AdminLayout><DeepDives /></AdminLayout></RequireAdmin></RequireAuth>} />
              <Route path="/admin/experiences" element={<RequireAuth><RequireAdmin><AdminLayout><AdminExperiences /></AdminLayout></RequireAdmin></RequireAuth>} />
              <Route path="/admin/completed-themes" element={<RequireAuth><RequireAdmin><AdminLayout><CompletedThemes /></AdminLayout></RequireAdmin></RequireAuth>} />
              <Route path="/admin/activity" element={<RequireAuth><RequireAdmin><AdminLayout><UserActivity /></AdminLayout></RequireAdmin></RequireAuth>} />
              <Route path="/admin/apps" element={<RequireAuth><RequireAdmin><AdminLayout><AdminApps /></AdminLayout></RequireAdmin></RequireAuth>} />
              <Route path="/admin/group-admin" element={<RequireAuth><AdminLayout><GroupAdminDashboard /></AdminLayout></RequireAuth>} />
              <Route path="/admin/testing" element={<RequireAuth><RequireAdmin><AdminLayout><TestingPage /></AdminLayout></RequireAdmin></RequireAuth>} />


              {/* Dashboard Route */}
              <Route
                path="/dashboard"
                element={
                  <div className="min-h-screen bg-gray-50">
                    <RequireAuth>
                      <RequirePaidAccess>
                        <Dashboard />
                      </RequirePaidAccess>
                    </RequireAuth>
                  </div>
                }
              />

              {/* Admin Routes */}
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route
                path="/admin"
                element={
                  <RequireAuth>
                    <RequireAdmin>
                    <AdminLayout>
                      <AdminDashboard />
                    </AdminLayout>
                    </RequireAdmin>
                  </RequireAuth>
                }
              >
                <Route index element={<AdminDashboard />} />
                <Route path="users" element={<UsersTable />} />
                <Route path="groups" element={<Groups />} />
                <Route path="group-admin" element={<GroupAdminDashboard />} />
                <Route path="deep-dives" element={<DeepDives />} />
                <Route path="experiences" element={<AdminExperiences />} />
                <Route path="completed-themes" element={<CompletedThemes />} />
                <Route path="activity" element={<UserActivity />} />
                <Route path="apps" element={<AdminApps />} />
              </Route>

              {/* Legal Routes */}
              <Route path="/terms" element={<TermsOfService />} />

              {/* 404 Route */}
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </div>
        </ReflectionsProvider>
      </Router>
    </AuthProvider>
  );
}