import React, { useState, useEffect } from 'react';
import { doc, collection, query, where, getDocs, updateDoc, getDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { X, Search } from 'lucide-react';
import { toast } from 'react-hot-toast';

interface EditGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  group: {
    id: string;
    group_name: string;
    group_code: string;
    total_seats: number;
    start_date: string;
    end_date: string;
    groupAdmin?: string;
  };
  onGroupUpdated: () => void;
}

interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

const addUserAsGroupAdmin = async (userId: string, groupId: string) => {
  console.log(`Adding user ${userId} as admin to group ${groupId}`);
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    group_admin_for: arrayUnion(groupId),
    group_admin: true
  });
};

const removeUserAsGroupAdmin = async (userId: string, groupId: string) => {
  console.log(`Removing user ${userId} as admin from group ${groupId}`);
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    group_admin_for: arrayRemove(groupId)
  });

  // Check if user has any remaining groups they admin
  const userDoc = await getDoc(userRef);
  const userData = userDoc.data();
  const remainingGroups = userData?.group_admin_for || [];
  
  if (remainingGroups.length === 0) {
    await updateDoc(userRef, {
      group_admin: false
    });
  }
};


export default function EditGroupModal({ isOpen, onClose, group, onGroupUpdated }: EditGroupModalProps) {
  const [form, setForm] = useState({ ...group });
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [currentAdmin, setCurrentAdmin] = useState<User | null>(null);

  useEffect(() => {
    if (isOpen && group.groupAdmin) {
      fetchCurrentAdmin();
    }
  }, [isOpen, group.groupAdmin]);

  const fetchCurrentAdmin = async () => {
    if (!group.groupAdmin) return;
    try {
      const userDoc = await getDocs(query(collection(db, 'users'), where('__name__', '==', group.groupAdmin)));
      if (!userDoc.empty) {
        const userData = userDoc.docs[0].data();
        setCurrentAdmin({
          id: userDoc.docs[0].id,
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName
        });
      }
    } catch (error) {
      console.error('Error fetching admin:', error);
    }
  };

  const searchUsers = async (term: string) => {
    if (!term.trim() || term.length < 2) {
      setSearchResults([]);
      return;
    }

    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef);
      const snapshot = await getDocs(q);

      const results = snapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        } as User))
        .filter(user => {
          const searchableText = `${user.email} ${user.firstName} ${user.lastName}`.toLowerCase();
          return searchableText.includes(term.toLowerCase());
        })
        .slice(0, 5);

      setSearchResults(results);
    } catch (error) {
      console.error('Error searching users:', error);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await updateDoc(doc(db, 'groups', group.id), {
        group_name: form.group_name,
        group_code: form.group_code,
        total_seats: form.total_seats,
        start_date: form.start_date,
        end_date: form.end_date,
        groupAdmin: form.groupAdmin
      });
      
      onGroupUpdated();
      onClose();
      toast.success('Group updated successfully');
    } catch (error) {
      console.error('Error updating group:', error);
      toast.error('Failed to update group');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center p-4 z-50 overflow-y-auto">
      <div className="bg-white rounded-lg max-w-2xl w-full p-6 my-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900">Edit Group</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Group Name</label>
            <input
              type="text"
              value={form.group_name}
              onChange={(e) => setForm({ ...form, group_name: e.target.value })}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Group Code</label>
            <input
              type="text"
              value={form.group_code}
              onChange={(e) => setForm({ ...form, group_code: e.target.value })}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Total Seats</label>
            <input
              type="number"
              value={form.total_seats}
              onChange={(e) => setForm({ ...form, total_seats: parseInt(e.target.value) })}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Start Date</label>
            <input
              type="date"
              value={form.start_date.split('T')[0]}
              onChange={(e) => setForm({ ...form, start_date: e.target.value })}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">End Date</label>
            <input
              type="date"
              value={form.end_date.split('T')[0]}
              onChange={(e) => setForm({ ...form, end_date: e.target.value })}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Group Admin</label>
            {currentAdmin && (
              <div className="mt-1 p-2 bg-gray-50 rounded-md">
                <div className="font-medium">{currentAdmin.firstName} {currentAdmin.lastName}</div>
                <div className="text-sm text-gray-500">{currentAdmin.email}</div>
              </div>
            )}
            <div className="relative mt-2">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  searchUsers(e.target.value);
                }}
                placeholder="Search users by email or name..."
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg"
              />
            </div>
            {searchResults.length > 0 && (
              <div className="mt-2 border border-gray-200 rounded-lg max-h-40 overflow-y-auto">
                {searchResults.map(user => (
                  <div
                    key={user.id}
                    className="flex items-center justify-between p-2 hover:bg-gray-50 cursor-pointer"
                    onClick={async () => {
                      try {
                        // Remove previous admin if exists
                        if (form.groupAdmin) {
                          await removeUserAsGroupAdmin(form.groupAdmin, group.id);
                        }
                        
                        // Add new admin
                        await addUserAsGroupAdmin(user.id, group.id);
                        
                        // Update form and UI state
                        setForm(prevForm => ({ ...prevForm, groupAdmin: user.id }));
                        setCurrentAdmin(user);
                        setSearchTerm('');
                        setSearchResults([]);
                      } catch (error) {
                        console.error('Error updating group admin:', error);
                        toast.error('Failed to update group admin');
                      }
                    }}
                  >
                    <div>
                      <div className="font-medium">{user.firstName} {user.lastName}</div>
                      <div className="text-sm text-gray-500">{user.email}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={loading}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
}