
import React from 'react';
import { motion } from 'framer-motion';

interface CardProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  interactive?: boolean;
  variant?: 'default' | 'outline' | 'ghost';
}

export function Card({ 
  children, 
  className = '', 
  onClick,
  interactive = false,
  variant = 'default'
}: CardProps) {
  const baseStyles = 'rounded-xl transition-all';
  
  const variants = {
    default: 'bg-white shadow-sm',
    outline: 'bg-white border border-gray-200',
    ghost: 'bg-transparent'
  };

  const Component = interactive ? motion.div : 'div';
  const interactiveProps = interactive ? {
    whileHover: { scale: 1.02 },
    whileTap: { scale: 0.98 },
    onClick,
    className: `cursor-pointer hover:shadow-md ${baseStyles} ${variants[variant]} ${className}`
  } : {
    className: `${baseStyles} ${variants[variant]} ${className}`
  };

  return (
    <Component {...interactiveProps}>
      {children}
    </Component>
  );
}

export function CardHeader({ children, className = '' }: { children: React.ReactNode; className?: string }) {
  return (
    <div className={`p-6 border-b border-gray-100 ${className}`}>
      {children}
    </div>
  );
}

export function CardTitle({ children, className = '' }: { children: React.ReactNode; className?: string }) {
  return (
    <h3 className={`text-lg font-semibold ${className}`}>
      {children}
    </h3>
  );
}

export function CardContent({ children, className = '' }: { children: React.ReactNode; className?: string }) {
  return (
    <div className={`p-6 ${className}`}>
      {children}
    </div>
  );
}

export function CardFooter({ children, className = '' }: { children: React.ReactNode; className?: string }) {
  return (
    <div className={`p-6 border-t border-gray-100 ${className}`}>
      {children}
    </div>
  );
}
