
import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../../../components/ui/Card";
import { Button } from "../../../components/ui/Button";
import { Globe, Save, Plus, Trash2 } from 'lucide-react';

const DEFAULT_ENVIRONMENTS = {
  production: 'https://www.findingblueberries.com',
  development: 'https://findingblueberries-dev.vercel.app'
};

const EnvironmentManager = ({ onEnvironmentsSaved }) => {
  const [environments, setEnvironments] = useState(DEFAULT_ENVIRONMENTS);
  const [newEnvName, setNewEnvName] = useState('');
  const [newEnvUrl, setNewEnvUrl] = useState('');
  const [saving, setSaving] = useState(false);

  const handleSave = async () => {
    setSaving(true);
    try {
      localStorage.setItem('testEnvironments', JSON.stringify(environments));
      if (onEnvironmentsSaved) {
        onEnvironmentsSaved(environments);
      }
    } catch (error) {
      console.error('Failed to save environments:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleAddEnvironment = () => {
    if (newEnvName && newEnvUrl) {
      setEnvironments(prev => ({
        ...prev,
        [newEnvName.toLowerCase()]: newEnvUrl
      }));
      setNewEnvName('');
      setNewEnvUrl('');
    }
  };

  return (
    <Card className="bg-gray-50">
      <CardHeader className="pb-2">
        <CardTitle className="text-sm flex items-center gap-2">
          <Globe className="w-4 h-4" />
          Configure Test Environments
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          <div className="flex gap-2 items-center">
            <input
              value={newEnvName}
              onChange={(e) => setNewEnvName(e.target.value)}
              placeholder="Environment name"
              className="h-8 px-2 text-sm border rounded flex-grow-0 w-40"
            />
            <input
              type="url"
              value={newEnvUrl}
              onChange={(e) => setNewEnvUrl(e.target.value)}
              placeholder="URL"
              className="h-8 px-2 text-sm border rounded flex-grow"
            />
            <Button
              variant="outline"
              size="sm"
              onClick={handleAddEnvironment}
              disabled={!newEnvName || !newEnvUrl}
            >
              <Plus className="w-4 h-4" />
            </Button>
          </div>

          {Object.entries(environments).map(([name, url]) => (
            <div key={name} className="flex items-center gap-2 text-sm">
              <span className="w-40 font-medium capitalize">{name}</span>
              <span className="flex-grow text-gray-600 truncate">{url}</span>
            </div>
          ))}

          <Button 
            onClick={handleSave}
            disabled={saving}
            className="w-full mt-4"
            size="sm"
          >
            <Save className="w-4 h-4 mr-2" />
            {saving ? 'Saving...' : 'Save Environments'}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default EnvironmentManager;
