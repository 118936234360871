
import React from 'react';
import { ChevronDown } from 'lucide-react';
import type { Group } from './GroupAdminDashboard';

interface GroupSelectorProps {
  groups: Group[];
  selectedGroup: Group | null;
  onGroupSelect: (group: Group) => void;
}

export default function GroupSelector({ groups, selectedGroup, onGroupSelect }: GroupSelectorProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50"
      >
        <span className="text-sm font-medium text-gray-900">
          {selectedGroup?.group_name || 'Select a group'}
        </span>
        <ChevronDown className="w-5 h-5 text-gray-400" />
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
          {groups.map(group => (
            <button
              key={group.id}
              onClick={() => {
                onGroupSelect(group);
                setIsOpen(false);
              }}
              className={`w-full px-4 py-2 text-left hover:bg-gray-100 ${
                selectedGroup?.id === group.id ? 'bg-indigo-50' : ''
              }`}
            >
              <div className="font-medium text-gray-900">{group.group_name}</div>
              <div className="text-sm text-gray-500">
                {group.members?.length || 0} / {group.total_seats} members
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
