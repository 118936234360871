import React from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

interface AdminLayoutProps {
  children: React.ReactNode;
}

export default function AdminLayout({ children }: AdminLayoutProps) {
  const location = useLocation();
  const { userData } = useAuth();

  const menuItems = [
    { path: '/admin/users', label: 'Users' },
    { path: '/admin/groups', label: 'Groups' },
    { path: '/admin/deep-dives', label: 'Deep Dives' },
    { path: '/admin/experiences', label: 'Experiences' },
    { path: '/admin/completed-themes', label: 'Completed Themes' },
    { path: '/admin/activity', label: 'Activity' },
    { path: '/admin/apps', label: 'Apps' },
    { path: '/admin/group-admin', label: 'Group Admin' },
    { path: '/admin/testing', label: 'Testing' }
  ];

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-2">
            <h1 className="text-2xl font-bold text-gray-900">Admin Dashboard</h1>
          </div>
          <nav className="flex space-x-4 py-2">
            {menuItems.map((item) => {
              // Show all items for super admin
              // Show only Group Admin for group admins
              if (userData?.access_level === 'admin' || 
                  (userData?.group_admin && item.path === '/admin/group-admin')) {
                return (
                  <Link
                    key={item.path}
                    to={item.path}
                    className={`px-3 py-2 rounded-md text-sm font-medium ${
                      location.pathname === item.path
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-600 hover:bg-gray-200'
                    }`}
                  >
                    {item.label}
                  </Link>
                );
              }
              return null;
            })}
          </nav>
        </div>
      </header>
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {children}
      </main>
    </div>
  );
}