import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Route, Users, CalendarDays, Sparkles } from 'lucide-react';
import { Button } from './ui/Button';

interface Feature {
  title: string;
  description: string;
  buttonText: string;
  route: string;
  icon: React.ReactNode;
}

const features: Feature[] = [
  {
    title: "Initiate Your Reflection",
    description: "Begin with five thought-provoking questions that illuminate what truly matters. Whether contemplating your own path or someone else's influence, each response unveils new insights and opens gateways to profound understanding.",
    buttonText: "Try a Reflection",
    route: "/deeper-reflections",
    icon: <Route className="w-6 h-6 text-indigo-600" />
  },
  {
    title: "Craft Deep Dive Narratives",
    description: "Through introspective reflection, weave summaries into refined narratives of life's pivotal chapters. Witness how your story intertwines with others' as you help illuminate their unique journeys.",
    buttonText: "Try Deep Dives",
    route: "/deeper-reflections",
    icon: <Sparkles className="w-6 h-6 text-indigo-600" />
  },
  {
    title: "Distill Value Essences",
    description: "Transform meaningful experiences into nuanced value distillations. Capture the quintessence of significant moments in your story and others', revealing the intricate ways diverse journeys enrich one another.",
    buttonText: "Create Shared Reflection",
    route: "/shared-experiences",
    icon: <Users className="w-6 h-6 text-indigo-600" />
  },
  {
    title: "Chronicle Daily Insights",
    description: "Cultivate a practice of capturing daily value through brief, 60-second micro-reflections. These seemingly small yet profound insights gradually complete the mosaic of your story and others' ongoing journeys.",
    buttonText: "Reflect on Today's Question",
    route: "/daily-reflection",
    icon: <CalendarDays className="w-6 h-6 text-indigo-600" />
  }
];

function FeatureCard({ title, description, buttonText, route, icon, index }: Feature & { index: number }) {
  const navigate = useNavigate();
  
  return (
    <motion.div 
      className="bg-white p-8 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg border border-indigo-100 flex flex-col"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <div className="flex items-center space-x-3 mb-4">
        <div className="p-2 bg-indigo-50 rounded-lg">
          {icon}
        </div>
        <h4 className="text-2xl font-semibold text-indigo-800">{title}</h4>
      </div>
      <p className="text-indigo-600 mb-6 flex-grow">{description}</p>
      <Button
        onClick={() => navigate(route)}
        className="w-full mt-auto bg-indigo-600 text-white hover:bg-white hover:text-indigo-600 border-2 border-indigo-600 transition-colors duration-300"
      >
        {buttonText}
      </Button>
    </motion.div>
  );
}

export default function TestHome() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white">
      <header className="bg-transparent text-indigo-900 py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h1 
            className="text-2xl font-bold"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Finding Blueberries
          </motion.h1>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <section className="py-20 text-center">
          <motion.h2 
            className="text-4xl md:text-5xl font-bold mb-6 text-indigo-900"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            Welcome to Finding Blueberries
          </motion.h2>
          <motion.p 
            className="text-xl md:text-2xl mb-8 max-w-3xl mx-auto text-indigo-700"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.7, delay: 0.4 }}
          >
            A place to gather around the fire, share meaningful stories, and uncover your true value—while helping your pack discover and embrace theirs.
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <Button
              onClick={() => navigate('/deeper-reflections')}
              className="bg-indigo-600 hover:bg-indigo-700 text-white px-8 py-3 text-lg"
            >
              Start Your Journey
            </Button>
          </motion.div>
        </section>

        {/* Introduction Section */}
        <section className="py-16">
          <motion.div 
            className="max-w-3xl mx-auto text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
          >
            <h3 className="text-3xl font-bold mb-6 text-indigo-800">Discover Meaningful Reflections</h3>
            <p className="text-xl text-indigo-600">
              Uncover how sharing profound reflections nurtures a natural cycle of deeper self-awareness and more authentic connections.
            </p>
          </motion.div>
        </section>

        {/* Features Section */}
        <section className="py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {features.map((feature, index) => (
              <FeatureCard key={index} {...feature} index={index} />
            ))}
          </div>
        </section>

        {/* Call to Action */}
        <section className="py-20 text-center">
          <motion.h3 
            className="text-3xl font-bold mb-6 text-indigo-900"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
          >
            Deepen Your Connections
          </motion.h3>
          <motion.p 
            className="text-xl mb-8 max-w-3xl mx-auto text-indigo-700"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            Each reflection weaves a new thread into the tapestry of your narrative. As you craft snapshots, summaries, and stories about yourself and others, you'll uncover the intricate ways your journeys intertwine, creating profound meaning together.
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Button
              onClick={() => navigate('/purchase')}
              className="bg-indigo-600 hover:bg-indigo-700 text-white px-8 py-3 text-lg"
            >
              Commit to Connection
            </Button>
          </motion.div>
        </section>
      </main>

      <footer className="bg-transparent text-indigo-600 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <p>&copy; {new Date().getFullYear()} Finding Blueberries. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}