import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col items-center justify-center space-y-4">
          <div className="flex items-center space-x-2">
            <span className="text-2xl">🫐</span>
            <span className="text-lg font-semibold text-gray-900">Finding Blueberries</span>
          </div>
          
          <nav className="flex space-x-6">
            <Link 
              to="/terms" 
              className="text-gray-600 hover:text-gray-900 transition-colors"
            >
              Terms of Service
            </Link>
            <a 
              href="mailto:brian@findinggood.com"
              className="text-gray-600 hover:text-gray-900 transition-colors"
            >
              Contact
            </a>
          </nav>

          <p className="text-sm text-gray-500">
            Built by{' '}
            <a 
              href="https://producthacker.ai" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-purple-600 hover:text-purple-700"
            >
              Cam Fortin
            </a>
            {' '}for{' '}
            <a 
              href="https://www.linkedin.com/in/bfretwell/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-purple-600 hover:text-purple-700"
            >
              Brian Fretwell
            </a>
            {' '}of{' '}
            <a 
              href="https://www.findinggood.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-purple-600 hover:text-purple-700"
            >
              Finding Good
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}