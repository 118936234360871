
import React, { useState } from 'react';
import TestRunner from './TestRunner';
import EnvironmentManager from './EnvironmentManager';
import { Button } from "../../../components/ui/Button";
import { Settings } from 'lucide-react';

const TestingPage = () => {
  const [environments, setEnvironments] = useState(() => {
    const saved = localStorage.getItem('testEnvironments');
    return saved ? JSON.parse(saved) : {
      production: 'https://www.findingblueberries.com',
      development: 'https://findingblueberries-dev.vercel.app'
    };
  });
  const [showSettings, setShowSettings] = useState(false);

  return (
    <div className="space-y-6 max-w-4xl mx-auto">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Test Runner</h2>
        <Button onClick={() => setShowSettings(!showSettings)} variant="outline" size="sm">
          <Settings className="w-4 h-4 mr-2" />
          Test Settings
        </Button>
      </div>
      
      {showSettings && (
        <EnvironmentManager onEnvironmentsSaved={setEnvironments} />
      )}
      
      {environments && <TestRunner environments={environments} />}
    </div>
  );
};

export default TestingPage;
