import React, { useState, useEffect } from 'react';
import { X, Plus, Trash2 } from 'lucide-react';
import { addDoc, collection, doc, updateDoc, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { toast } from 'react-hot-toast';
import type { Theme } from '../../lib/firebase/types';

interface EditThemeModalProps {
  isOpen: boolean;
  onClose: () => void;
  theme?: Theme;
  experienceId?: string;
  onSaved: () => void;
}

export default function EditThemeModal({
  isOpen,
  onClose,
  theme,
  experienceId,
  onSaved
}: EditThemeModalProps) {
  const [form, setForm] = useState({
    title: '',
    description: '',
    showCreatedFor: false,
    addToTop: false,
    systemPrompt: '',
    order: 1,
    questions: [{
      id: 1,
      text: '',
      followUpPrompts: [],
      neuroscience: '',
      closingNote: ''
    }]
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (theme) {
      setForm({
        title: theme.title,
        description: theme.description,
        showCreatedFor: theme.showCreatedFor || false,
        systemPrompt: theme.systemPrompt || '',
        order: theme.order,
        questions: theme.questions
      });
    } else {
      setForm({
        title: '',
        description: '',
        order: 1,
        showCreatedFor: false,
        systemPrompt: '',
        questions: [{
          id: 1,
          text: '',
          digDeeper: [],
          whyItMatters: '',
          bestPractices: ''
        }]
      });
    }
  }, [theme]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!experienceId) return;
    
    setLoading(true);

    try {
      // Get current max order
      const themesRef = collection(db, 'themes');
      let newOrder = 1;
      
      if (!theme) {
        // For new themes
        const q = query(themesRef, where('experienceId', '==', experienceId), orderBy('order', 'desc'), limit(1));
        const snapshot = await getDocs(q);
        const maxOrder = !snapshot.empty ? snapshot.docs[0].data().order : 0;
        newOrder = form.addToTop ? 1 : maxOrder + 1;

        // If adding to top, increment all other themes' orders
        if (form.addToTop) {
          const allThemesQuery = query(themesRef, where('experienceId', '==', experienceId));
          const allThemesSnapshot = await getDocs(allThemesQuery);
          await Promise.all(
            allThemesSnapshot.docs.map(doc => 
              updateDoc(doc.ref, { order: doc.data().order + 1 })
            )
          );
        }
      }

      const timestamp = new Date().toISOString();
      const themeData = {
        ...form,
        order: theme ? form.order : newOrder,
        experienceId,
        updatedAt: timestamp,
        ...(theme ? {} : { createdAt: timestamp })
      };

      delete themeData.addToTop; // Remove UI-only field

      if (theme) {
        await updateDoc(doc(db, 'themes', theme.id), themeData);
        toast.success('Theme updated successfully');
      } else {
        await addDoc(collection(db, 'themes'), themeData);
        toast.success('Theme created successfully');
      }
      
      onSaved();
      onClose();
    } catch (error: any) {
      console.error('Error saving theme:', error);
      toast.error(error.message || 'Failed to save theme');
    } finally {
      setLoading(false);
    }
  };

  const addQuestion = () => {
    setForm(prev => ({
      ...prev,
      questions: [
        ...prev.questions,
        {
          id: prev.questions.length + 1,
          text: '',
          followUpPrompts: [],
          neuroscience: '',
          closingNote: ''
        }
      ]
    }));
  };

  const removeQuestion = (id: number) => {
    setForm(prev => ({
      ...prev,
      questions: prev.questions.filter(q => q.id !== id)
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center p-4 z-50 overflow-y-auto">
      <div className="bg-white rounded-lg max-w-3xl w-full p-6 my-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900">
            {theme ? 'Edit Theme' : 'Add Theme'}
          </h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              value={form.title}
              onChange={(e) => setForm({ ...form, title: e.target.value })}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              value={form.description}
              onChange={(e) => setForm({ ...form, description: e.target.value })}
              required
              rows={3}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Custom System Prompt</label>
            <textarea
              value={form.systemPrompt}
              onChange={(e) => setForm({ ...form, systemPrompt: e.target.value })}
              rows={6}
              placeholder="Leave blank to use default system prompt"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 font-mono text-sm"
            />
            <p className="mt-1 text-sm text-gray-500">
              Custom prompt to use when generating summaries for this theme. If left blank, the default prompt will be used.
            </p>
          </div>

          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="showCreatedFor"
              checked={form.showCreatedFor}
              onChange={(e) => setForm({ ...form, showCreatedFor: e.target.checked })}
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label htmlFor="showCreatedFor" className="text-sm font-medium text-gray-700">
              Show "Created for" field
            </label>
          </div>

          {!theme && (
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="addToTop"
                checked={form.addToTop}
                onChange={(e) => setForm({ ...form, addToTop: e.target.checked })}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor="addToTop" className="text-sm font-medium text-gray-700">
                Add to top of list
              </label>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700">Order</label>
            <input
              type="number"
              value={form.order}
              onChange={(e) => setForm({ ...form, order: parseInt(e.target.value) })}
              required
              min={1}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <div>
            <div className="flex justify-between items-center mb-2">
              <label className="block text-sm font-medium text-gray-700">Questions</label>
              <button
                type="button"
                onClick={addQuestion}
                className="flex items-center space-x-1 text-sm text-indigo-600 hover:text-indigo-700"
              >
                <Plus className="w-4 h-4" />
                <span>Add Question</span>
              </button>
            </div>
            <div className="space-y-2">
              {form.questions.map((question, index) => {
                const updateQuestionField = (field: string, value: any) => {
                  setForm(prev => ({
                    ...prev,
                    questions: prev.questions.map(q =>
                      q.id === question.id ? { ...q, [field]: value } : q
                    )
                  }));
                };

                return (
                  <div key={question.id} className="bg-gray-50 p-4 rounded-lg space-y-4">
                    <div className="flex items-start space-x-2">
                      <div className="flex-1">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Question {index + 1}
                        </label>
                        <input
                          type="text"
                          value={question.text}
                          onChange={(e) => updateQuestionField('text', e.target.value)}
                          placeholder="Enter question text"
                          required
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        />
                      </div>
                      {form.questions.length > 1 && (
                        <button
                          type="button"
                          onClick={() => removeQuestion(question.id)}
                          className="p-2 text-red-600 hover:text-red-700"
                        >
                          <Trash2 className="w-4 h-4" />
                        </button>
                      )}
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center justify-between">
                        <span>Dig Deeper (one per line)</span>
                        <span className="text-xs text-gray-500">Optional</span>
                      </label>
                      <textarea
                        value={question.digDeeper?.join('\n') || ''}
                        onChange={(e) => updateQuestionField('digDeeper', e.target.value.split('\n'))}
                        placeholder="Enter prompts to dig deeper, one per line"
                        rows={3}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center justify-between">
                        <span>Why It Matters</span>
                        <span className="text-xs text-gray-500">Optional</span>
                      </label>
                      <textarea
                        value={question.whyItMatters || ''}
                        onChange={(e) => updateQuestionField('whyItMatters', e.target.value)}
                        placeholder="Enter why this reflection matters"
                        rows={2}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center justify-between">
                        <span>Best Practices</span>
                        <span className="text-xs text-gray-500">Optional</span>
                      </label>
                      <textarea
                        value={question.bestPractices || ''}
                        onChange={(e) => updateQuestionField('bestPractices', e.target.value)}
                        placeholder="Enter best practices for this reflection"
                        rows={2}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
            >
              {loading ? 'Saving...' : 'Save Theme'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
