import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { User, LogOut, Settings, ChevronDown, LayoutDashboard, Users, CalendarDays, Route, BookOpen, ShoppingCart } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

export default function Header() {
  const location = useLocation();
  const { user, userData, signOut } = useAuth();
  const [showUserMenu, setShowUserMenu] = React.useState(false);
  const menuRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const isActive = (path: string) => location.pathname === path;

  const menuItems = [
    { path: '/dashboard', label: 'Dashboard', icon: LayoutDashboard },
    { path: '/deeper-reflections', label: 'Deep Dives', icon: Route },
    { path: '/shared-experiences', label: 'Shared', icon: Users },
    { path: '/daily-reflection', label: 'Daily', icon: CalendarDays },
    ...(user ? [{ path: '/admin/group-admin', label: 'Group Management', icon: Settings }] : []),
    ...(userData?.isAdmin ? [{ path: '/admin', label: 'Admin Dashboard', icon: Settings }] : []),
  ];

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <div className="flex items-center space-x-8 flex-1">
            <Link to="/" className="flex items-center space-x-3 hover:opacity-75 transition-opacity">
              <span className="text-2xl">🫐</span>
              <span className="text-lg md:text-xl font-semibold text-indigo-900">Finding Blueberries</span>
            </Link>
            <div className="hidden md:flex items-center space-x-8">
              {user && (
                <>
                  {menuItems.map((item) => (
                    item.path !== '/admin/group-admin' && ( //Exclude Group Management from this section
                      <Link
                        key={item.path}
                        to={item.path}
                        className="flex items-center space-x-2 text-gray-600 hover:text-gray-900 transition-colors"
                      >
                        <item.icon className="w-4 h-4" />
                        <span>{item.label}</span>
                      </Link>
                    )
                  ))}
                </>
              )}
            </div>
          </div>
          {user ? (
            <div className="relative" ref={menuRef}>
              <button
                onClick={() => setShowUserMenu(!showUserMenu)}
                className="flex items-center space-x-2 px-3 py-2 rounded-lg hover:bg-gray-50"
                aria-expanded={showUserMenu}
              >
                <div className="w-8 h-8 bg-indigo-100 rounded-full flex items-center justify-center">
                  <User className="w-4 h-4 text-indigo-600" />
                </div>
                <div className="flex flex-col items-start">
                  <span className="text-gray-700">{userData?.firstName}</span>
                  {userData?.access_level === 'free' && (
                    <span className="text-xs text-red-500">Limited</span>
                  )}
                </div>
                <ChevronDown className="w-4 h-4 text-gray-400" />
              </button>
              {showUserMenu && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50">
                  {userData?.access_level === 'free' && (
                    <Link
                      to="/purchase"
                      className="flex items-center space-x-2 px-4 py-2 text-gray-700 hover:bg-gray-50"
                      onClick={() => setShowUserMenu(false)}
                    >
                      <ShoppingCart className="w-4 h-4" />
                      <span>Upgrade</span>
                    </Link>
                  )}
                  {menuItems.map((item) => (
                    <Link
                      key={item.path}
                      to={item.path}
                      className="flex items-center space-x-2 px-4 py-2 text-gray-700 hover:bg-gray-50"
                      onClick={() => setShowUserMenu(false)}
                    >
                      <item.icon className="w-4 h-4" />
                      <span>{item.label}</span>
                    </Link>
                  ))}
                  <button
                    onClick={() => {
                      signOut();
                      setShowUserMenu(false);
                    }}
                    className="w-full flex items-center space-x-2 px-4 py-2 text-gray-700 hover:bg-gray-50"
                  >
                    <LogOut className="w-4 h-4" />
                    <span>Sign Out</span>
                  </button>
                  <div className="px-4 py-2 text-xs text-gray-400 border-t">
                    ID: {user?.uid}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Link
              to="/login"
              className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
            >
              <User className="w-4 h-4" />
              <span>Sign In</span>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
}