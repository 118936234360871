import React from 'react';
import { motion } from 'framer-motion';
import { X, ArrowLeft, ArrowRight, Route, Lightbulb, BookOpen, ChevronDown, ChevronUp, ArrowDownCircle } from 'lucide-react';
import ReactMarkdown from 'react-markdown';

interface QuestionModalProps {
  theme: {
    title: string;
    questions: Array<{
      id: number;
      text: string;
      digDeeper?: string[];
      whyItMatters?: string;
      bestPractices?: string;
    }>;
  } | null;
  currentQuestion: number;
  answers: Record<number, string>;
  onClose: () => void;
  onAnswer: (questionId: number, answer: string) => void;
  onNext: () => void;
  onPrevious: () => void;
  onComplete: () => void;
  onDaySelect?: (day: number) => void;
  isGeneratingSummary?: boolean;
  summary?: string;
}

interface CollapsibleSectionProps {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  defaultOpen?: boolean;
}

function CollapsibleSection({ title, icon, children, defaultOpen = false }: CollapsibleSectionProps) {
  const [isOpen, setIsOpen] = React.useState(defaultOpen);
  
  return (
    <div className="border border-gray-100 rounded-lg overflow-hidden bg-white shadow-sm">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between p-3 hover:bg-gray-50 transition-colors"
      >
        <div className="flex items-center space-x-2">
          {icon}
          <span className="font-medium text-gray-700">{title}</span>
        </div>
        {isOpen ? (
          <ChevronUp className="w-4 h-4 text-gray-400" />
        ) : (
          <ChevronDown className="w-4 h-4 text-gray-400" />
        )}
      </button>
      <motion.div
        initial={false}
        animate={{ height: isOpen ? 'auto' : 0 }}
        className="overflow-hidden"
      >
        <div className="p-3 pt-0">
          {children}
        </div>
      </motion.div>
    </div>
  );
}

export default function QuestionModal({
  theme,
  currentQuestion,
  answers,
  onClose,
  onAnswer,
  onNext,
  onPrevious,
  onComplete,
  onDaySelect,
  isGeneratingSummary = false,
  summary
}: QuestionModalProps) {
  if (!theme) return null;
  
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const [currentContent, setCurrentContent] = React.useState('');
  const [isHelpersOpen, setIsHelpersOpen] = React.useState(false);
  const totalQuestions = theme?.questions.length || 5;
  const isSummaryPage = currentQuestion === totalQuestions + 1;
  const [localContent, setLocalContent] = React.useState(answers[currentQuestion] || '');

  // Update textarea content when switching questions
  React.useEffect(() => {
    if (!isSummaryPage) {
      const answer = answers[currentQuestion] || '';
      setLocalContent(answer);
      if (textareaRef.current) {
        textareaRef.current.value = answer;
      }
    }
  }, [currentQuestion, answers, isSummaryPage]);

  const question = theme.questions.find(q => q.id === currentQuestion);
  if (!question && !isSummaryPage && currentQuestion <= totalQuestions) return null;

  const isLastQuestion = currentQuestion === theme.questions.length;
  const hasExistingSummary = !!summary;

  return (
    <div className="fixed inset-0 bg-gradient-to-b from-gray-900 to-black flex items-center justify-center z-40">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-white w-full h-[85vh] relative flex flex-col max-w-5xl mx-auto m-4 rounded-xl"
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 z-10"
        >
          <X className="w-5 h-5" />
        </button>
        
        <div className="flex-1 overflow-y-auto custom-scrollbar pr-4">
          <div className="p-8 space-y-6 max-w-4xl mx-auto w-full">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-base text-gray-400">{theme.title}</h2>
              {!isSummaryPage && (
                <div className="flex items-center space-x-4">
                  <div className="flex items-center space-x-2">
                    {[...Array(theme.questions.length + 1)].map((_, i) => (
                      <button
                        key={i}
                        onClick={() => {
                          const num = i + 1;
                          if (num === theme.questions.length + 1 && hasExistingSummary) {
                            onDaySelect?.(6);
                          } else {
                            if (answers[num] || num === currentQuestion) {
                              onDaySelect?.(num);
                            }
                          }
                        }}
                        className={`w-8 h-8 rounded-full flex items-center justify-center ${
                          currentQuestion === i + 1
                            ? 'bg-purple-600 text-white'
                            : answers[i + 1]
                            ? 'bg-green-100 text-green-600'
                            : i === theme.questions.length && hasExistingSummary
                            ? 'bg-green-100 text-green-600 cursor-pointer'
                            : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                        }`}
                      >
                        {i === theme.questions.length ? 'S' : i + 1}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="space-y-4">
              {isSummaryPage ? (
                <div className="prose max-w-none [&>ul]:list-none [&>ul]:pl-0">
                  <h1 className="text-3xl font-bold text-gray-900 mb-6">Theme Summary</h1>
                  <ReactMarkdown>{summary}</ReactMarkdown>
                </div>
              ) : (
                <>
                  <p className="text-3xl font-bold text-gray-900 leading-tight mb-4">{question.text}</p>
                  
                  <div className="space-y-4">
                    <div className="bg-gradient-to-r from-purple-50 to-indigo-50 rounded-lg border border-purple-100 shadow-sm overflow-hidden">
                      <button
                        onClick={() => setIsHelpersOpen(!isHelpersOpen)}
                        className="w-full flex items-center justify-between p-4 hover:bg-white/50 transition-colors"
                      >
                        <div className="flex items-center space-x-2">
                          <span className="text-lg font-medium bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">
                            Blueberry Helpers
                          </span>
                        </div>
                        {isHelpersOpen ? (
                          <ChevronUp className="w-5 h-5 text-purple-600" />
                        ) : (
                          <ChevronDown className="w-5 h-5 text-purple-600" />
                        )}
                      </button>
                      
                      <motion.div
                        initial={false}
                        animate={{ height: isHelpersOpen ? 'auto' : 0 }}
                        className="overflow-hidden"
                      >
                        <div className="p-4 space-y-2">
                      {question?.digDeeper && question.digDeeper.length > 0 && (
                        <CollapsibleSection
                          title="Dig Deeper"
                          icon={<Lightbulb className="w-5 h-5 text-purple-600" />}
                          defaultOpen={false}
                        >
                          <ul className="space-y-1">
                            {question.digDeeper.map((prompt, index) => (
                              <li key={index} className="text-purple-600">
                                {prompt}
                              </li>
                            ))}
                          </ul>
                        </CollapsibleSection>
                      )}
                      
                      {question?.whyItMatters && (
                        <CollapsibleSection
                          title="Why It Matters"
                          icon={<Route className="w-5 h-5 text-blue-600" />}
                          defaultOpen={false}
                        >
                          <p className="text-blue-600">{question.whyItMatters}</p>
                        </CollapsibleSection>
                      )}

                      {question?.bestPractices && (
                        <CollapsibleSection
                          title="Best Practices"
                          icon={<BookOpen className="w-5 h-5 text-green-600" />}
                          defaultOpen={false}
                        >
                          <p className="text-green-600">{question.bestPractices}</p>
                        </CollapsibleSection>
                      )}
                        </div>
                      </motion.div>
                    </div>
                    
                    <textarea
                      ref={textareaRef}
                      value={localContent}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setLocalContent(newValue);
                        onAnswer(currentQuestion, newValue);
                      }}
                      placeholder="Take your time to reflect and write your thoughts..."
                      className="w-full p-6 border border-purple-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent resize-y h-[200px] min-h-[200px] text-lg mb-6"
                    />
                  </div>
                </>
              )}
            </div>

          <div className="flex justify-between items-center">
            <button
              onClick={onPrevious}
              disabled={currentQuestion === 1}
              className="flex items-center space-x-2 px-6 py-3 text-purple-600 hover:bg-purple-50 rounded-lg disabled:opacity-50 transition-colors"
            >
              <ArrowLeft className="w-4 h-4" />
              <span>Previous</span>
            </button>

            {currentQuestion === theme.questions.length ? (
              <button
                onClick={onComplete}
                disabled={!answers[currentQuestion]?.trim() || isGeneratingSummary}
                className={`flex items-center space-x-2 px-8 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 disabled:opacity-50 transition-colors text-lg font-medium ${isGeneratingSummary ? 'cursor-wait' : ''}`}
              >
                {isGeneratingSummary ? (
                  <>
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                    <span>Generating Summary...</span>
                  </>
                ) : (
                  <span>Generate Summary</span>
                )}
              </button>
            ) : (
              <button
                onClick={onNext}
                disabled={!answers[currentQuestion]?.trim()}
                className="flex items-center space-x-2 px-8 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 disabled:opacity-50 transition-colors text-lg font-medium"
              >
                <span>Next</span>
                <ArrowRight className="w-4 h-4" />
              </button>
            )}
          </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}