
import React, { useState } from 'react';
import { X } from 'lucide-react';
import { collection, query, where, getDocs, doc, updateDoc, arrayUnion, setDoc, getDoc } from 'firebase/firestore';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth, db } from '../../lib/firebase';
import { toast } from 'react-hot-toast';

interface AddGroupMemberModalProps {
  isOpen: boolean;
  onClose: () => void;
  group: {
    id: string;
    group_code: string;
    seats_remaining: number;
    end_date: string;
  };
  onMemberAdded: () => void;
}

export default function AddGroupMemberModal({
  isOpen,
  onClose,
  group,
  onMemberAdded
}: AddGroupMemberModalProps) {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(true);
  const [password, setPassword] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email.trim()) return;

    try {
      setLoading(true);

      // Check if user exists
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '==', email.toLowerCase()));
      const snapshot = await getDocs(q);

      let userId;
      const timestamp = new Date().toISOString();

      if (snapshot.empty) {
        if (!showCreateForm) {
          setShowCreateForm(true);
          return;
        }

        try {
          // create firebase user
          const createResponse = await fetch(`/api/user-admin`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              email: email,
              password: password,
              displayName: `${firstName} ${lastName}`,
            })
          });

          if (!createResponse.ok) {
            console.error('Error creating user:', createResponse.statusText);
            toast.error('Failed to create user account');
            return;
          }
          const data = await createResponse.json();
          userId = data.user.uid;

          // Create user document
          await setDoc(doc(db, 'users', userId), {
            firstName,
            lastName,
            email: email.toLowerCase(),
            group: group.group_code,
            access_level: 'group_membership',
            createdAt: timestamp,
            membership_start: timestamp,
            membership_end: group.end_date
          });

          toast.success('New user account created');
          await sendPasswordResetEmail(auth, email);
        } catch (error: any) {
          if (error.code === 'auth/email-already-in-use') {
            toast.error('An account already exists with this email');
          } else {
            console.error('Error creating user:', error);
            toast.error('Failed to create user account');
          }
          return;
        }
      } else {
        const userDoc = snapshot.docs[0];
        userId = userDoc.id;
        const userData = userDoc.data();

        // Check if user is already in a group
        if (userData.group) {
          toast.error('User is already in a group');
          return;
        }

        // Update existing user
        await updateDoc(doc(db, 'users', userId), {
          group: group.group_code,
          access_level: 'group_membership',
          membership_start: timestamp,
          membership_end: group.end_date
        });
      }

      // Update group
      const groupRef = doc(db, 'groups', group.id);
      const oldGroupDoc = await getDoc(groupRef);
      const oldGroupData = oldGroupDoc.data();

      if (!oldGroupData) {
        throw new Error('Group not found');
      }

      await updateDoc(groupRef, {
        members: arrayUnion(userId),
        seats_used: Math.max(0, (oldGroupData.seats_used || 0) + 1),
        seats_remaining: (oldGroupData.seats_remaining || 0) - 1
      });

      
      toast.success('Member added successfully');
      onMemberAdded();
      onClose();
    } catch (error) {
      console.error('Error adding member:', error);
      toast.error('Failed to add member');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900">Add Group Member</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email address"
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          {showCreateForm && (
            <>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    First Name
                  </label>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Last Name
                  </label>
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Temporary Password
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  minLength={6}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
                <p className="mt-1 text-sm text-gray-500">
                  User will need to reset this password on first login
                </p>
              </div>
            </>
          )}

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
            >
              {loading ? 'Adding...' : showCreateForm ? 'Create & Add User' : 'Add Member'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
